import React, { Component } from 'react';
import {
  Form, InputNumber, Button, message,
  PageHeader, Row, Col, Spin,
} from 'antd';

import _ from 'lodash';

import amsAPI from '../../apis/amsAPI';

import 'antd/dist/antd.css';
import './CreateForm.css';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

class LocaleChurchPopulation extends Component {
  state = {
    record: {},
    localeChurches: [],
    loadingRecord: true,
    submitting: false,
  };

  componentDidMount() {
    this.getRequiredInfoFromAPI();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.getRequiredInfoFromAPI();
    }
  }

  getRequiredInfoFromAPI = async () => {
    this.setState({ loadingRecord: true });
    const { localeId } = this.props.match.params;
    this.callApi(`/ams/locale_churches/${localeId}/population`)
      .then(res => {
        this.setState({ record: res.data || {}, loadingRecord: false })
      });
  }

  callApi = async (url) => {
    const response = await amsAPI.getUrl(url)
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  updateRecord = async (updatedInfo) => {
    const currentInfo = this.state.record;
    this.setState({
      record: { ...currentInfo, ...updatedInfo }
    });
  };

  handleSubmit = async e => {
    e.preventDefault();
    const { localeId } = this.props.match.params;
    const { record } = this.state;
    this.setState({ submitting: true });
    amsAPI.fetchUrl(`/ams/locale_churches/${localeId}/population`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({...record}),
    })
    .then(async res => {
      if (res.status === 200) {
        const response = await res.json();
        if (!response.error_id) {
          message.success('Locale population successfully updated.');
          this.setState({ submitting: false });
          this.props.history.push(`/locale_churches`);
        }
      } else {
        const error = new Error(res.error);
        throw error;
      }
    })
    .catch(err => {
      console.error(err);
      this.setState({ submitting: false });
      message.error('Error submitting.');
    });
  };

  render() {
    const { record, submitting, loadingRecord } = this.state;
    const { localeChurch, registered, inactive, inconsistent } = record;
    const loading = loadingRecord;
    if (loading) {
      return (
        <div className="wrap">
          <div className="extraContent">
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12} style={{ textAlign: "center" }}>
                <Spin size="large" />
              </Col>
            </Row>
          </div>
        </div>
      )
    }

    return (
      <PageHeader>
        <div className="wrap">
          <div className="extraContent">
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <h3>{`Locale Population ${!_.isEmpty(localeChurch) ? `: ${localeChurch.name.toUpperCase()}` : ""}`}</h3>
              </Col>
            </Row>
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12}>
                <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                  <Form.Item label="Registered">
                    <InputNumber
                      onChange={value => this.updateRecord({ registered: value })}
                      value={registered}
                    />
                  </Form.Item>
                  <Form.Item label="Inactive">
                    <InputNumber
                      onChange={value => this.updateRecord({ inactive: value })}
                      value={inactive}
                    />
                  </Form.Item>
                  <Form.Item label="Inconsistent">
                    <InputNumber
                      onChange={value => this.updateRecord({ inconsistent: value })}
                      value={inconsistent}
                    />
                  </Form.Item>
                  <Form.Item {...tailFormItemLayout}>
                    <Button block type="primary"
                      loading={submitting}
                      onClick={this.handleSubmit}
                    >
                      {"Update"}
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </div>
        </div>
      </PageHeader>
    );
  }
}

export default LocaleChurchPopulation;
