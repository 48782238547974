import React, { Component } from 'react';
import amsAPI from '../../apis/amsAPI';
import {
  Button, Col, Form, Input, Row, message,
} from 'antd';

import 'antd/dist/antd.css';
import './Ministry.css';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

class CreateMinistry extends Component {
  state = {
    name: '',
    code: '',
    description: '',
  };

  handleSubmit = async e => {
    e.preventDefault();
    await amsAPI.fetchUrl('/ams/ministries', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: this.state.name,
        code: this.state.code,
        description: this.state.description,
      }),
    })
    .then(res => {
      if (res.status === 200) {
        message.success("Ministry successfully created.");
        this.props.history.push('/ministries');
      } else if (res.status === 422) {
        message.error("Ministry already registered.");
      }
    })
    .catch(err => {
      message.error("Error in registering ministry.");
      console.log(err);
    });
  };

  render() {
    return (
      <div className="wrap">
        <div className="extraContent">
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}>
              <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                <Form.Item
                  label="Ministry Name:"
                >
                  <Input
                    value={this.state.name}
                    onChange={e => this.setState({ name: e.target.value })}
                  />
                </Form.Item>
                <Form.Item
                  label="Ministry Code:"
                >
                  <Input
                    value={this.state.code}
                    onChange={e => this.setState({ code: e.target.value })}
                  />
                </Form.Item>
                <Form.Item
                  label="Description:"
                >
                  <Input
                    value={this.state.description}
                    onChange={e => this.setState({ description: e.target.value })}
                  />
                </Form.Item>
                <Form.Item {...tailFormItemLayout}>
                  <Button type="primary" htmlType="submit">Register</Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default CreateMinistry;
