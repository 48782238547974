import React, { Component } from 'react';
import {
  Form, Input, Button, Row, Col, Spin, message
} from 'antd';

import amsAPI from '../../apis/amsAPI';

import 'antd/dist/antd.css';
import './Gathering.css';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
    lg: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
    lg: { span: 8 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

class SharedGatheringLink extends Component {
  state = {
    url: '',
    linkInfo: {},
    loading: false,
  };

  componentDidMount() {
    this.getRequireInfoFromAPI();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.getRequireInfoFromAPI();
    }
  }

  getRequireInfoFromAPI = async () => {
    this.setState({ loading: true });
    this.getSharedLink()
      .then(res => {
        this.setState({ linkInfo: res, url: res.url, loading: false });
      })
      .catch(err => console.log(err));
  }

  getSharedLink = async () => {
    const { gatheringId } = this.props.match.params;
    const response = await amsAPI.getUrl(`/ams/gatherings/${gatheringId}/link`);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  onChange = async ({ target: { value } }) => {
    this.setState({ url: value });
  };

  handleSubmit = async e => {
    e.preventDefault();
    const { gatheringId } = this.props.match.params;
    const { url } = this.state;
    await amsAPI.fetchUrl(`/ams/gatherings/${gatheringId}/link`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ url }),
    });
    message.success("Gathering link successfully submitted.");
    this.props.history.push("/gatherings");
  };

  render() {
    const { url, loading } = this.state;
    if (loading) {
      return (
        <div className="wrap">
          <div className="extraContent">
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12} style={{ textAlign: "center" }}>
                <Spin size="large" />
              </Col>
            </Row>
          </div>
        </div>
      )
    }

    return (
      <Form {...formItemLayout} onSubmit={this.handleSubmit}>
        <Form.Item label="Access Link:">
          <Input value={url} onChange={this.onChange} />
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit">Update</Button>
        </Form.Item>
      </Form>
    );
  }
}

export default SharedGatheringLink;
