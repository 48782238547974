import React, { Component } from 'react';
import {
  Form, Input, Button, message
} from 'antd';

import amsAPI from '../../apis/amsAPI';

import 'antd/dist/antd.css';
import './Gathering.css';

const { TextArea } = Input;

class CreateGatheringLink extends Component {
  state = {
    urls: '',
  };

  onChange = async ({ target: { value } }) => {
    this.setState({ urls: value });
  };

  handleSubmit = async e => {
    e.preventDefault();
    const { gatheringId } = this.props.match.params;
    const urls = this.state.urls.split(/\r?\n/);
    await amsAPI.fetchUrl(`/ams/gatherings/${gatheringId}/links`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ urls }),
    });
    message.success("Gathering links successfully submitted.");
    this.props.history.push(`/gatherings/${gatheringId}/links`);
  };

  render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        lg: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        lg: { span: 8 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };

    return (
      <Form {...formItemLayout} onSubmit={this.handleSubmit}>
        <Form.Item label="URL Links:">
          <TextArea autosize={{ minRows: 10 }} onChange={this.onChange} />
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit">Register</Button>
          <p>{this.state.responseToPost}</p>
        </Form.Item>
      </Form>
    );
  }
}

export default CreateGatheringLink;
