import React, { Component } from 'react';
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { Button, Col, Row, Spin, Table, message, Icon, Switch, Form, Select } from 'antd';
import { stringify } from 'query-string';

import moment from 'moment';

import amsAPI from '../../apis/amsAPI';

import 'antd/dist/antd.css';
import './List.css';

const Option = Select.Option;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
};

let timeout;
let currentValue;

function fetch(params, callback) {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = params.value;

  async function fake() {
    const { value, gatheringId } = params;
    const query = { receiptNumber: value, gatheringId };
    amsAPI.getUrl(`/ams/gatherings/${gatheringId}/receipt_numbers?${stringify(query)}`)
      .then(async response => {
        const body = await response.json();
        if (response.status !== 200) throw Error(body.message);
        return body;
      })
      .then(d => {
        if (currentValue === value) {
          callback(d.receiptNumbers);
        }
      });
  }

  timeout = setTimeout(fake, 300);
}

class AttendanceApproval extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      members: [],
      localeChurchNames: [],
      selectedRowKeys: [],
      selectedRows: [],
      receiptNumbers: [],
      loadingMembers: false,
    };
    this.rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({ selectedRowKeys, selectedRows });
      },
    };
  }

  createTableColumns = async () => {
    this.columns = [
      {
        title: 'Name',
        dataIndex: 'memberId',
        key: 'memberId',
        sorter: (a, b) => a.memberId.name.localeCompare(b.memberId.name),
        render: (text, record) => {
          if (record.memberId.lineLink) {
            return (
              <a href={record.memberId.lineLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                {(record.memberId && record.memberId.name) ? record.memberId.name : ""}
              </a>
            );
          }
          return (
            <span>
            {(record.memberId && record.memberId.name) ? record.memberId.name : ""}
            </span>
          )
        },
      },
      {
        title: 'Locale Church',
        dataIndex: 'memberId.localeChurchId',
        key: 'memberId.localeChurchId',
        render: (text, record) => (
          <span>
            {(record.memberId && record.memberId.localeChurchId) ? record.memberId.localeChurchId.name : ""}
          </span>
        ),
      },
      {
        title: 'Submission',
        dataIndex: 'submissionDateTime',
        key: 'submissionDateTime',
        render: (text, record) => (
          <span>
            {record.submissionDateTime ?
              `${moment(record.submissionDateTime).format("MMM.DD(ddd), h:mmA")}`
              :
              null
            }
          </span>
        ),
      },
      {
        title: 'IP Address',
        dataIndex: 'ipAddress',
        key: 'ipAddress',
        render: (text, record) => (
          <span>
            {record.ipAddress ?
              record.ipAddress
              :
              null
            }
          </span>
        ),
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <span>
            <Switch
              checkedChildren="Accepted"
              unCheckedChildren="Declined"
              defaultChecked={record.isApproved}
              onChange={(checked) => this.handleUpdate(record._id, checked)}
            />
          </span>
        ),
      },
    ];
  }

  componentDidMount() {
    this.getAttendees()
      .then(res => this.setState({ members: res.attendees, loadingMembers: false }))
      .catch(err => console.log(err));
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.getAttendees()
        .then(res => this.setState({ members: res.attendees, loadingMembers: false }))
        .catch(err => console.log(err));      
    }
  }

  getAttendees = async (query) => {
    this.setState({ loadingMembers: true });
    query = { ...query, sortApproved: true };
    const { gatheringId } = this.props.match.params;
    const response = await amsAPI.getUrl(`/ams/gatherings/${gatheringId}/attendees?${stringify(query)}`);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  refresh = () => {
    this.getAttendees({  })
      .then(res => this.setState({ members: res.attendees, loadingMembers: false }))
      .catch(err => console.log(err));
  }

  handleUpdate = async (_id, isApproved) => {
    await amsAPI.fetchUrl(`/ams/member_attendance/${_id}`, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ isApproved }),
    });
    message.success(`Attendance successfully ${isApproved ? "approved" : "rejected"}.`);
    const { gatheringId } = this.props.match.params;
    this.props.history.push(`/gatherings/${gatheringId}/approvals`);
  };

  handleBulkUpdate = async (isApproved) => {
    const { selectedRowKeys } = this.state;
    await amsAPI.fetchUrl(`/ams/member_attendance`, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ isApproved, ids: selectedRowKeys }),
    });
    message.success(`Attendance successfully ${isApproved ? "approved" : "rejected"}.`);
    const { gatheringId } = this.props.match.params;
    this.props.history.push(`/gatherings/${gatheringId}/approvals`);
  };

  handleFilter = async () => {
    const query = {
      receiptNumber: this.state.receiptNumber
    };
    this.setState({ loadingMembers: true });
    this.getAttendees(query)
      .then(res => this.setState({ members: res.attendees, loadingMembers: false }))
      .catch(err => console.log(err));
  };

  handleSearch = (value, gatheringId) => {
    if (value) {
      const params = { value, gatheringId };
      fetch(params, data => {
        this.setState({ receiptNumbers: data })
      });
    } else {
      this.setState({ receiptNumbers: [] });
    }
  };

  handleChange = receiptNumber => {
    this.setState({ receiptNumber });
  };

  render() {
    this.createTableColumns();
    const { gatheringId } = this.props.match.params;
    const { members, receiptNumbers, loadingMembers } = this.state;
    let modResult = [];
    let i = 0;
    members.forEach(item => {
      i++;
      modResult.push({ ...item, key: item._id, rowKey: { _id: item._id, rowNum: i } });
    });

    return (
      <div className="wrap">
        <div className="extraContent">
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}>
              <Form {...formItemLayout}>
                <Form.Item label="Receipt Number:">
                <Select
                      showSearch
                      placeholder="Receipt number id"
                      optionFilterProp="value"
                      defaultActiveFirstOption={false}
                      showArrow={false}
                      filterOption={false}
                      onSearch={(value) => this.handleSearch(value, gatheringId)}
                      onChange={this.handleChange}
                      notFoundContent={null}
                    >
                      {receiptNumbers.map(item => {
                        return (
                          <Option key={item.receiptNumber} value={item.receiptNumber}>
                            {item.receiptNumber}
                          </Option>
                        )
                      })}
                  </Select>
                  <Button
                    type="primary"
                    style={{marginLeft: '10px'}}
                    onClick={this.handleFilter}
                  > Filter</Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12} style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                type="primary"
                onClick={this.refresh}
              > <Icon type="reload"/></Button>
              <span>
                <Button
                  type="primary"
                  onClick={() => this.handleBulkUpdate(true)}
                  style={{ marginRight: "5px" }}
                >
                  <Icon type="check-circle"/>Accept
                </Button>
                <Button
                  type="danger"
                  onClick={() => this.handleBulkUpdate(false)}
                >
                  <Icon type="close-circle"/>Decline
                </Button>
              </span>
            </Col>
          </Row>
          {loadingMembers ?
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12} style={{ textAlign: "center" }}>
                <Spin size="large" />
              </Col>
            </Row>
          :
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12}>
              {(members.length === 0) ?
                <div>
                  <h3>{`Sorry, but there are no attendees for this gathering.`}</h3>
                </div>
              :
                <div>
                  <h3>{`Here are the list of attendees:`}</h3>
                  <Table
                    rowSelection={this.rowSelection}
                    pagination={false}
                    columns={this.columns}
                    dataSource={modResult}
                  />
                </div>
              }
              </Col>
            </Row>
          }
        </div>
      </div>
    );
  }
}

export default withRouter(AttendanceApproval);
