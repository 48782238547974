import React, { Component } from 'react';
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import {
  Button, Form, Row, Col, PageHeader, Spin,
  message, Input, Select
} from 'antd';
import ReactGA from 'react-ga';

import amsAPI from '../../../apis/amsAPI';

import 'antd/dist/antd.css';
import './CreateForm.css';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

class EditCountry extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props)
    this.state = {
      record: {},
      churchDivisions: [],
      churchDistricts: [],
      loadingRecord: false,
    }

    this.updateRecord = this.updateRecord.bind(this);
  }
  componentDidMount() {
    this.getRequiredInfoFromAPI();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.getRequiredInfoFromAPI();
    }
  }

  getRequiredInfoFromAPI = async () => {
    this.setState({ loadingRecord: true });
    this.getRecord()
      .then(res => {
        this.setState({ record: res.data, loadingRecord: false });
      })
    this.callApi('/ams/church_divisions')
      .then(res => {
        this.setState({ churchDivisions: res.data })
      });
    this.callApi('/ams/church_districts')
      .then(res => {
        this.setState({ churchDistricts: res.data })
      });
  }

  getRecord = async () => {
    const { _id } = this.props.match.params;
    const response = await amsAPI.getUrl(`/api/countries/${_id}`);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  callApi = async (url) => {
    const response = await amsAPI.getUrl(url)
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  updateRecord = async (updatedInfo) => {
    const currentInfo = this.state.record;
    this.setState({
      record: { ...currentInfo, ...updatedInfo }
    });
  };

  handleChurchDivisionSelect = async (value) => {
    this.updateRecord({ churchDivision: value });
    this.callApi(`/ams/church_districts?churchDivision=${value}`)
      .then(res => {
        this.setState({ churchDistricts: res.data })
      })
  };

  handleSubmit = async (e) => {
    ReactGA.event({
      category: 'Button Click',
      action: 'edit country'
    });

    e.preventDefault();
    const { record } = this.state;
    const { _id } = this.props.match.params;

    this.setState({ submitting: true });
    amsAPI.fetchUrl(`/api/countries/${_id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
      },
      body: JSON.stringify({...record}),
    })
    .then(async res => {
      if (res.status === 200) {
        const response = await res.json();
        if (!response.error_id) {
          message.success('Successfully added.');
          this.setState({ submitting: false });
          this.props.history.push(`/countries`);
        }
      } else {
        const error = new Error(res.error);
        throw error;
      }
    })
    .catch(err => {
      console.error(err);
      this.setState({ submitting: false });
      message.error('Error submitting.');
    });
  };

  render() {
    const { loadingRecord, churchDistricts, churchDivisions } = this.state;
    const { name, churchDivision, churchDistrict } = this.state.record;
    const disableSubmit = !name;
    const loading =  loadingRecord;
    if (loading) {
      return (
        <div className="wrap">
          <div className="extraContent">
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12} style={{ textAlign: "center" }}>
                <Spin size="large" />
              </Col>
            </Row>
          </div>
        </div>
      )
    }

    return (
      <PageHeader>
        <div className="wrap">
          <div className="extraContent">
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12}>
                <Form {...formItemLayout}>
                  <Form.Item label="Name:">
                    <Input
                      onChange={e => this.updateRecord({ name: e.target.value })}
                      defaultValue={name}
                    />
                  </Form.Item>
                  <Form.Item label="Division:">
                    <Select
                      placeholder="Select a division"
                      dropdownMatchSelectWidth={false}
                      onChange={this.handleChurchDivisionSelect}
                      defaultValue={churchDivision}
                    >
                      {churchDivisions.map(item => {
                        return <Option key={item._id} value={item._id}>{item.name}</Option>
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item label="District:">
                    <Select
                      placeholder="Select a district"
                      dropdownMatchSelectWidth={false}
                      onChange={value => this.updateRecord({ churchDistrict: value })}
                      disabled={churchDistricts.length === 0}
                      defaultValue={churchDistrict}
                    >
                      {churchDistricts.map(item => {
                        return <Option key={item._id} value={item._id}>{item.name}</Option>
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item {...tailFormItemLayout}>
                    <Button block type="primary"
                      loading={this.state.submitting}
                      onClick={this.handleSubmit}
                      disabled={disableSubmit}
                    >
                      {"Submit"}
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </div>
        </div>
      </PageHeader>
    );
  }
}

export default withRouter(EditCountry);
