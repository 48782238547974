import React, { Component } from 'react';
import {
  Form, Button, DatePicker, Select, Switch, Input, Row, Col, message
} from 'antd';

import moment from "moment";
import _ from "lodash";

import amsAPI from '../../apis/amsAPI';
import disablePastDates from '../../helpers/functions';
import * as constants from '../../helpers/constants';

import 'antd/dist/antd.css';
import './Gathering.css';

const Option = Select.Option;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 12,
      offset: 6,
    },
  },
};

class CreateGathering extends Component {
  state = {
    response: [],
    gatheringTypes: [],
    name: '',
    type: 'live',
    scope: 'division',
    startDateTime: null,
    endDateTime: null,
    channels: ['mcgi.tv', 'zoom', 'youtube', 'facebook', 'instagram', 'satellite', 'others'],
    gatheringLink: '',
    isActive: false,
    responseToPost: '',
  };

  componentDidMount() {
    this.callApi()
      .then(res => this.setState({ gatheringTypes: res.data, loading: false }))
      .catch(err => console.log(err));
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.callApi()
        .then(res => this.setState({ gatheringTypes: res.data, loading: false }))
        .catch(err => console.log(err));
    }
  }

  callApi = async (query) => {
    this.setState({ loading: true });
    const response = await amsAPI.getUrl(`/api/gathering_types`);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  handleGatheringChange = name => {
    this.setState({ name: name })

    let defaultChannels = [];
    const zoomAndLineOnly = [ "nyr", "pmws", "pm", "ws", "pbb", "spbb", "ls", "tg", "spm" ];
    if (zoomAndLineOnly.includes(name)) {
      defaultChannels = ['zoom', 'others'];
    } else if (name === "sbk") {
      defaultChannels = [ 'zoom', 'mcgi.tv', 'instagram', 'youtube', 'satellite' ];
    } else if (name === "aods") {
      defaultChannels = [ "zoom" ];
    } else {
      defaultChannels = ['zoom', 'youtube', 'facebook', 'mcgi.tv', 'instagram', 'others'];
    }

    this.setState({ channels: defaultChannels });
  }

  handleSubmit = async e => {
    e.preventDefault();
    await amsAPI.fetchUrl(`/ams/gatherings`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: this.state.name,
        type: this.state.type,
        scope: this.state.scope,
        startDateTime: this.state.startDateTime,
        endDateTime: this.state.endDateTime,
        channels: this.state.channels,
        gatheringLink: this.state.gatheringLink,
        active: this.state.isActive,
      }),
    })
    .then(res => {
      if (res.status === 200) {
        message.success("Gathering successfully created.");
        this.props.history.push('/gatherings');
      } else if (res.status === 422) {
        message.error("Gathering already registered.");
      }
    })
    .catch(err => {
      message.error("Error in registering gathering.");
      console.log(err);
    });

  };

  render() {
    let {
      name, type, scope, channels, startDateTime, gatheringTypes,
    } = this.state
    const gatheringEntries = [];
    gatheringTypes.forEach(item => {
      gatheringEntries.push([item.code, item.name]);
    })
    const replayOrLive = Object.entries(constants.replayOrLive);
    const scopes = Object.entries(constants.scopes);
    const channelEntries = Object.entries(constants.channels);

    const disableSubmit = !name || !type || !scope || _.isEmpty(channels) || !startDateTime;

    return (
      <div className="wrap">
        <div className="extraContent">
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={18}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <h2>Gathering Form</h2>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={18}>
              <Form {...formItemLayout}>
                <Form.Item label="Gathering">
                  <Select
                    showSearch
                    placeholder="Select a gathering"
                    dropdownMatchSelectWidth={false}
                    style={{ width: 240 }}
                    onChange={this.handleGatheringChange}
                  >
                    {gatheringEntries.map(([id, name]) =>
                      <Option key={id} value={id}>{name}</Option>
                    )}
                  </Select>
                </Form.Item>
                <Form.Item label="Type">
                  <Select
                    showSearch
                    placeholder="Select a gathering type"
                    dropdownMatchSelectWidth={false}
                    style={{ width: 240 }}
                    onChange={value => this.setState({ type: value })}
                    defaultValue={this.state.type}
                  >
                    {replayOrLive.map(([id, name]) =>
                      <Option key={id} value={id}>{name}</Option>
                    )}
                  </Select>
                </Form.Item>
                <Form.Item label="Scope">
                  <Select
                    placeholder="Select a scope"
                    dropdownMatchSelectWidth={false}
                    style={{ width: 240 }}
                    onChange={value => this.setState({ scope: value })}
                    defaultValue={this.state.scope}
                  >
                    {scopes.map(([id, name]) =>
                      <Option key={id} value={id}>{name}</Option>
                    )}
                  </Select>
                </Form.Item>
                <Form.Item label="Start Date">
                  <DatePicker
                    showToday
                    format="YYYY-MM-DD h:mm a"
                    showTime={{ use12Hours: true, format: "h:mm a" }}
                    placeholder="Start Date Time"
                    value={this.state.startDateTime}
                    onChange={(value) => this.setState({ startDateTime: moment(value).startOf('minute') })}
                    disabledDate={disablePastDates}
                  />
                </Form.Item>
                <Form.Item label="End Date">
                  <DatePicker
                    showToday
                    format="YYYY-MM-DD h:mm a"
                    showTime={{ use12Hours: true, format: "h:mm a" }}
                    placeholder="End Date Time"
                    value={this.state.endDateTime}
                    onChange={(value) => this.setState({ endDateTime: moment(value).endOf('minute') })}
                    disabledDate={disablePastDates}
                  />
                </Form.Item>
                <Form.Item label="Channels">
                  <Select
                    placeholder="Please select channels"
                    dropdownMatchSelectWidth={false}
                    style={{ width: 240 }}
                    onChange={(value) => this.setState({ channels: value })}
                    allowClear={true}
                    mode={"multiple"}
                    value={channels}
                  >
                    {channelEntries.map(([id, name]) =>
                      <Option key={id} value={id}>{name}</Option>
                    )}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Gathering Link"
                >
                  <Input
                    onChange={(e) => this.setState({ gatheringLink: e.target.value })}
                  />
                </Form.Item>
                <Form.Item label="Is active?" colon={false} >
                  <Switch
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    onChange={(checked) => this.setState({ isActive: checked })}
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}>
              <Form {...formItemLayout}>
                <Form.Item {...tailFormItemLayout}>
                  <Button
                    block
                    onClick={this.handleSubmit}
                    type="primary"
                    htmlType="submit"
                    disabled={disableSubmit}
                  >Register</Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default CreateGathering;
