import React, { Component } from 'react';
import PropTypes from "prop-types";
import {
  Form, Select, Button, Row, Col,
} from 'antd';

import * as qs from 'query-string';

import amsAPI from '../../apis/amsAPI';

import 'antd/dist/antd.css';
import './Filter.css';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 19 },
  },
};

let timeout;
let currentValue;

function fetch(params, callback) {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = params.value;

  async function fake() {
    const { value } = params;
    const query = { q: value };
    amsAPI.getUrl(`/ams/members?${qs.stringify(query)}`)
      .then(async response => {
        const body = await response.json();
        if (response.status !== 200) throw Error(body.message);
        return body;
      })
      .then(d => {
        if (currentValue === value) {
          callback(d.members);
        }
      });
  }

  timeout = setTimeout(fake, 300);
}

class LocaleChurchesFilter extends Component {
  static propTypes = {
    setFilter: PropTypes.func.isRequired,
    handleFilter: PropTypes.func.isRequired,
  };

  constructor() {
		super();
    this.state = {
      churchDivisions: [],
      churchDistricts: [],
      countries: [],
    };
  }

  componentDidMount() {
    this.getRequiredInfoFromAPI();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.getRequiredInfoFromAPI();
    }
  }

  getRequiredInfoFromAPI = async () => {
    const { churchDivision, churchDistrict, country } = this.props.filter;
    console.log('filter', this.props.filter)
    let query = { };
    if (churchDivision) query.churchDivision = churchDivision
    if (churchDistrict) query.churchDistrict = churchDistrict
    if (country) query.country = country
    console.log('query', query)
    this.callApi(`/ams/church_divisions?${qs.stringify(query)}`)
      .then(res => {
        this.setState({ churchDivisions: res.data })
      });
    this.callApi(`/ams/church_districts?${qs.stringify(query)}`)
      .then(res => {
        this.setState({ churchDistricts: res.data })
      });
    this.callApi(`/ams/countries?${qs.stringify(query)}`)
      .then(res => {
        this.setState({ countries: res.data })
      });
  }

  callApi = async (url) => {
    const response = await amsAPI.getUrl(url)
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  handleChurchDivisionSelect = async (value) => {
    this.props.setFilter({ churchDivision: value })

    let query = { };
    if (value) query.churchDivision = value

    this.callApi(`/ams/church_districts?${qs.stringify(query)}`)
      .then(res => {
        this.setState({ churchDistricts: res.data });
        this.props.setFilter({ churchDistrict: null, country: null })
      })
    this.callApi(`/ams/countries?${qs.stringify(query)}`)
      .then(res => {
        this.setState({ countries: res.data })
      })
  };

  handleChurchDistrictSelect = async (value) => {
    this.props.setFilter({ churchDistrict: value })

    let query = { };
    if (value) query.churchDistrict = value

    this.callApi(`/ams/countries?${qs.stringify(query)}`)
      .then(res => {
        this.setState({ countries: res.data })
      })
  };

  handleSearch = (value) => {
    if (value) {
      const params = { value };
      fetch(params, data => {
        this.setState({ members: data })
      });
    } else {
      this.setState({ members: [] });
    }
  }

  render() {
    const {
      churchDivisions, churchDistricts, countries,
    } = this.state;
    const { churchDivision, churchDistrict, country } = this.props.filter;
    
    return (
      <div>
        <Row type="flex" justify="center">
          <Col xs={24} sm={24} md={24} lg={12}>
            <Form {...formItemLayout}>
              <Form.Item label="Division:">
                <Select
                  placeholder="Select a division"
                  dropdownMatchSelectWidth={false}
                  onChange={this.handleChurchDivisionSelect}
                  defaultValue={churchDivision}
                  allowClear={true}
                >
                  {churchDivisions.map(item => {
                    return <Option key={item._id} value={item._id}>{item.name}</Option>
                  })}
                </Select>
              </Form.Item>
              <Form.Item label="District:">
                <Select
                  placeholder="Select a district"
                  dropdownMatchSelectWidth={false}
                  onChange={this.handleChurchDistrictSelect}
                  disabled={churchDistricts.length === 0}
                  defaultValue={churchDistrict}
                  allowClear={true}
                >
                  {churchDistricts.map(item => {
                    return <Option key={item._id} value={item._id}>{item.name}</Option>
                  })}
                </Select>
              </Form.Item>
              <Form.Item label="Country:">
                <Select
                  placeholder="Select a country"
                  dropdownMatchSelectWidth={false}
                  onChange={value => this.props.setFilter({ country: value })}
                  disabled={countries.length === 0}
                  defaultValue={country}
                  allowClear={true}
                >
                  {countries.map(item => {
                    return <Option key={item._id} value={item._id}>{item.name}</Option>
                  })}
                </Select>
              </Form.Item>
              <Form.Item>
                <Button
                  style={{marginLeft: '10px'}}
                  onClick={this.props.handleFilter}
                > Filter</Button>  
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }
}

export default LocaleChurchesFilter;
