import React, { Component } from 'react';
import {
  Button, Col, Form, Row, Select, Spin, Card, Checkbox, message,
} from 'antd';
import { withTranslation } from 'react-i18next';
import ReactGA from 'react-ga';
import moment from 'moment';
import querystring from 'querystring';
import _ from 'lodash';

import amsAPI from '../../apis/amsAPI';

import 'antd/dist/antd.css';
import './Attendance.css';

const Option = Select.Option;

let timeout;
let currentValue;

function fetch(value, ministry, callback) {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = value;

  async function fake() {
    const str = querystring.encode({
      nameOrChurchId: value,
      ministryName: ministry,
    });
    amsAPI.getUrl(`/api/query/members?${str}`)
      .then(async response => {
        const body = await response.json();
        if (response.status !== 200) throw Error(body.message);
        return body;
      })
      .then(d => {
        if (currentValue === value) {
          callback(d.members);
        }
      });
  }

  timeout = setTimeout(fake, 300);
}

class MinistryAttendance extends Component {
  state = {
    mode: '',
    members: [],
    churchLocales: [],
    gatheringTypes: [],
    loadingActivity: true,
    submitting: false,
  };

  componentDidMount() {
    this.getRequiredInfoFromAPI();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.getRequiredInfoFromAPI();
    }
  }

  getRequiredInfoFromAPI = async () => {
    this.setState({ loadingActivity: true });

    this.callApi(`/api/gathering_types`)
      .then(res => this.setState({ gatheringTypes: res.data, loading: false }))
      .catch(err => console.log(err));

    this.props.setActiveActivity({});
    let { ministry, scope } = this.props;
    const str = querystring.encode({
      active: true,
      ministry, scope,
    });
    this.callApi(`/api/gatherings?${str}`)
      .then(res => {
        this.props.setActiveActivity(res.data[0]);
        this.setState({ loadingActivity: false });
      })
      .catch(err => console.log(err));
  }

  callApi = async (url) => {
    const response = await amsAPI.getUrl(url);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  handleSubmitAttendance = async (e) => {
    ReactGA.event({
      category: 'Button Click',
      action: 'submit attendance'
    });

    e.preventDefault();
    this.setState({ submitting: true });
    const memberIds = this.props.checkedMembers.map(item => item)
    const { t, activeActivity } = this.props;
    const payload = {
      memberIds: memberIds,
    };

    amsAPI.fetchUrl(`/ams/gatherings/${activeActivity._id}/attendees`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
    .then(async res => {
      if (res.status === 200) {
        const response = await res.json();
        if (!response.error_id) {
          message.success('Attendance successfully submitted.');
          const { receiptNumber } = response.data[0];
          this.props.setReceiptNumber(receiptNumber);
          this.setState({ submitting: false });
          if (this.props.ministry) {
            this.props.history.push(`/ministry_attendance_receipt/${this.props.ministry}`);
          } else {
            this.props.history.push(`/ministry_attendance_receipt`);
          }

        } else if (!_.isEmpty(response.error_id)) {
          this.setState({ submitting: false });
          message.error(t(response.error_id));
          this.getRequiredInfoFromAPI();
        }
      } else if (res.status === 422) {
        this.setState({ submitting: false });
        message.error(t('Attendance already submitted.'));
      } else {
        const error = new Error(res.error);
        throw error;
      }
    })
    .catch(err => {
      console.error(err);
      this.setState({ submitting: false });
      message.error(t('Error submitting attendance.'));
    });
  };

  handleSearch = value => {
    if (value) {
      fetch(value, this.props.ministry, data => {
        this.setState({ members: data })
      });
    } else {
      this.setState({ members: [] });
    }
  };

  handleChange = selectedMembers => {
    this.props.setMembers(selectedMembers)
  };

  render() {
    let { members, loadingActivity, gatheringTypes } = this.state;
    const { t, checkedMembers, activeActivity, ministry } = this.props;
    const ministryCapitalized = ministry.charAt(0).toUpperCase() + ministry.slice(1)

    const gatherings = {};
    gatheringTypes.forEach(item => {
      gatherings[item.code] = item.name;
    })

    if (loadingActivity) {
      return (
        <div className="wrap">
          <div className="extraContent">
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12} style={{ textAlign: "center" }}>
                <Spin size="large" />
              </Col>
            </Row>
          </div>
        </div>
      )
    }

    if (_.isEmpty(activeActivity)) {
      return (
        <div className="wrap">
          <div className="extraContent">
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={18}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <h3>{t(`${ministryCapitalized} Ministry's Attendance Submission`)}</h3>
              </Col>
            </Row>
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={18}>
                <Card style={{display: "flex", justifyContent: "center"}}>
                  <div style={{ fontSize: '1.5em', color: '#08c', textAlign: "center", whiteSpace: "wrap" }}>
                    {t("Sorry, but attendance submission is currently closed.")}
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      )
    }

    return (
      <div className="wrap">
        <div className="extraContent">
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={18}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <h3>{t(`${ministryCapitalized} Ministry's Attendance Submission`)}</h3>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={18}>
              <Row type="flex" justify="center">
                <Col xs={24} sm={24} md={24} lg={12}>
                  <Card style={{display: "flex", justifyContent: "center"}}>
                    <div style={{ fontSize: '2em', color: '#08c', textAlign: "center", whiteSpace: "nowrap" }}>
                      {
                        `${moment(activeActivity.startDateTime).format("MMM.DD (ddd)")}`
                      }
                    </div>
                    <div style={{ fontSize: '2em', color: '#08c', textAlign: "center", whiteSpace: "nowrap" }}>
                      {
                        `${moment(activeActivity.startDateTime).format("h:mmA")}`
                      }
                    </div>
                  </Card>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                  <Card style={{display: "flex", justifyContent: "center"}}>
                    <div style={{ fontSize: '2em', color: '#08c', textAlign: "center" }}>
                      {
                        `${activeActivity.name ? t(gatherings[activeActivity.name]) : ""}`
                      }
                    </div>
                  </Card>
                </Col>
              </Row>
              <Form>
                <Form.Item label={t("Please select those who attended")}>
                  <Select
                      showSearch
                      placeholder={t("Input member name or id")}
                      dropdownMatchSelectWidth={false}
                      mode={this.state.mode}
                      optionFilterProp="value"
                      defaultActiveFirstOption={false}
                      showArrow={false}
                      filterOption={false}
                      onSearch={this.handleSearch}
                      onChange={this.handleChange}
                      value={checkedMembers}
                    >
                      {members.map(item => {
                        return (
                          <Option key={item._id} value={item._id}>
                            {`${item.churchId} ${item.name}`}
                          </Option>
                        )
                      })}
                  </Select>
                  <Checkbox
                    checked={this.state.mode === 'multiple' ? true : false}
                    onChange={e => this.setState({ mode: e.target.checked ? 'multiple' : '' })}
                  >{t("Are other brethren attending with you?")}</Checkbox>
                </Form.Item>
              </Form>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}>
              <Button block type="primary"
                disabled={_.isEmpty(activeActivity) || _.isEmpty(checkedMembers)}
                loading={this.state.submitting}
                onClick={this.handleSubmitAttendance}
              >
                {t("Submit")}
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default withTranslation()(MinistryAttendance);
