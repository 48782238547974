import React, { Component } from 'react';
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { Col, Row, Button, } from 'antd';

import 'antd/dist/antd.css';
import './Form.css';

class RegistrationReceipt extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  returnHome = async (e) => {
    e.preventDefault();
    this.props.history.push("/");
  };

  render() {
    const { referenceNumber } = this.props;

    return (
      <div className="wrap">
        <div className="extraContent">
          {referenceNumber ?
            <div>
              <Row type="flex" justify="center">
                <Col xs={24} sm={24} md={24} lg={24} style={{ textAlign: "center" }}>
                  <div>
                    <h3>Candidate information has been successfully submitted.</h3>
                    <span>Registration reference number is:</span>
                    <h2>{referenceNumber}</h2>
                  </div>
                </Col>
              </Row>
            </div>
            :
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={24} style={{ textAlign: "center" }}>
                <div><Button onClick={this.returnHome}>Return to home</Button></div>
              </Col>
            </Row>
          }
        </div>
      </div>
    );
  }
}

export default withRouter(RegistrationReceipt);
