import React, { Component } from 'react';
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import {
  Input, Button, Form, Row, Col, PageHeader, Spin
} from 'antd';

import amsAPI from '../../../apis/amsAPI';

import 'antd/dist/antd.css';
import './Role.css';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

class EditRole extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  state = {
    role: {},
    loading: false,
  };

  componentDidMount() {
    this.getRequiredInfoFromAPI();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.getRequiredInfoFromAPI();
    }
  }

  getRequiredInfoFromAPI = async () => {
    this.setState({ loading: true });
    this.getRole()
      .then(res => {
        this.setState({ role: res.data, loading: false });
      })
      .catch(err => console.log(err));
  }

  handleSubmit = async e => {
    e.preventDefault();
    const { role } = this.state;
    const { _id } = this.props.match.params;
    await amsAPI.fetchUrl(`/api/roles/${_id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({...role}),
    });
    this.props.history.push('/system/roles');
  }

  getRole = async () => {
    const { _id } = this.props.match.params;
    const response = await amsAPI.getUrl(`/api/roles/${_id}`);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  updateInfo = async (updatedInfo) => {
    const currentInfo = this.state.role;
    this.setState({
      role: { ...currentInfo, ...updatedInfo }
    });
  };

  render() {
    const { role, loading } = this.state;
    if (loading) {
      return (
        <div className="wrap">
          <div className="extraContent">
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12} style={{ textAlign: "center" }}>
                <Spin size="large" />
              </Col>
            </Row>
          </div>
        </div>
      )
    }

    return (
      <PageHeader>
        <div className="wrap">
          <div className="extraContent">
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12}>
                <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                  <Form.Item
                    label="Name:"
                  >
                    <Input
                      defaultValue={role.name}
                      onChange={(e) => this.updateInfo({ name: e.target.value })}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Code:"
                  >
                    <Input
                      defaultValue={role.code}
                      onChange={e => this.updateInfo({ code: e.target.value })}
                    />
                  </Form.Item>
                  <Form.Item {...tailFormItemLayout}>
                    <Button type="primary" htmlType="submit">Update</Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </div>
        </div>
      </PageHeader>
    );
  }
}

export default withRouter(EditRole);
