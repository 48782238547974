import React, { Component } from 'react';
import PropTypes from "prop-types";
import { NavLink } from 'react-router-dom';
import { withRouter } from "react-router";
import {
  Button, Col, Row, Spin, Table, Popconfirm, message,
} from 'antd';
import { LocaleChurchesFilter } from '../filters';

import * as qs from 'query-string';
import amsAPI from '../../apis/amsAPI';

import 'antd/dist/antd.css';
import './List.css';

class LocaleChurches extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      filter: {},
      items: [],
      loading: false,
    };
  }

  createTableColumns = async () => {
    this.columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: (text, record) =>
          <NavLink
            to={`/locale_churches/${record._id}/show`}
          >
            {record.name}
          </NavLink>,
      },
      {
        title: 'Division',
        dataIndex: 'churchDivision',
        key: 'churchDivision',
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: (text, record) =>
          <span>{record.churchDivision ? record.churchDivision.name : null}</span>,
      },
      {
        title: 'District',
        dataIndex: 'churchDistrict',
        key: 'churchDistrict',
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: (text, record) =>
          <span>{record.churchDistrict ? record.churchDistrict.name : null}</span>,
      },
      {
        title: 'Country',
        dataIndex: 'country',
        key: 'country',
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: (text, record) =>
          <span>{record.country ? record.country.name : null}</span>,
      },
      {
        title: <div>Members <br/>(Reg | Ina | Inc)</div>,
        dataIndex: 'localeChurchPopulation',
        key: 'localeChurchPopulation',
        render: (text, record) => {
          const { registered, inactive, inconsistent } = record;
          return (
            <span>
              {`${registered ? registered : 0} | ${inactive ? inactive : 0} | ${inconsistent ? inconsistent : 0}`}
            </span>
          )
        },
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <span>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => this.handleDelete(record._id)}
            >
              <Button type="link" icon="delete" />
            </Popconfirm>
            <NavLink
              to={`/locale_churches/${record._id}/edit`}
            >
              <Button type="link" icon="edit" />
            </NavLink>
            <NavLink
              to={`/locale_churches/${record._id}/population`}
            >
              <Button type="link" icon="team" />
            </NavLink>
          </span>
        ),
      },
    ];
  }

  componentDidMount() {
    const { userMemberId } = this.props;
    let itemsQuery = { userMemberId };
    this.getItems(itemsQuery)
      .then(res => {
        this.setState({ items: res.data, loading: false })
      });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      const { userMemberId } = this.props;
      let itemsQuery = { userMemberId };
      this.getItems(itemsQuery)
        .then(res => {
          this.setState({ items: res.data, loading: false })
        });
    }
  }

  getItems = async (query) => {
    this.setState({ loading: true });
    const response = await amsAPI.getUrl(`/api/locale_churches/simple?${qs.stringify(query)}`)
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  handleDelete = async (_id) => {
    await amsAPI.fetchUrl(`/api/locale_churches/${_id}`, {
      method: 'DELETE',
    });
    message.success("Locale church successfully deleted.")
    this.props.history.push('/locale_churches');
  };

  setFilter = async (updatedInfo) => {
    const currentInfo = this.state.filter;
    this.setState({
      filter: { ...currentInfo, ...updatedInfo }
    });
  };

  handleFilter = () => {
    const { churchDivision, churchDistrict, country } = this.state.filter;
    let query = { };
    if (churchDivision) query.churchDivision = churchDivision
    if (churchDistrict) query.churchDistrict = churchDistrict
    if (country) query.country = country
    this.getItems(query)
      .then(res => {
        this.setState({ items: res.data, loading: false })
      })
  }

  render() {
    this.createTableColumns();
    const { items, loading, filter } = this.state;
    let modResult = [];
    if (items && items.length > 0) {
      let i = 0;
      items.forEach(item => {
        modResult.push({ ...item, key: i++ });
      });
    }

    return (
      <div className="wrap">
        <div className="extraContent">
          <Row type="flex" justify="center">
          <Col xs={24} sm={24} md={24} lg={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <h3>Locale Churches Page</h3>
            </Col>
          </Row>
          <LocaleChurchesFilter
            setFilter={this.setFilter}
            handleFilter={this.handleFilter}
            filter={filter}
          />
          {loading ?
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12} style={{ textAlign: "center" }}>
                <Spin size="large" />
              </Col>
            </Row>
          :
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={20}>
              {(items.length === 0) ?
                <div>
                  <h3>{`Sorry, but there are no registered locale churches.`}</h3>
                </div>
              :
                <div>
                  <h3>{`Here are the list of registered locale churches:`}</h3>
                  <Table
                    columns={this.columns}
                    dataSource={modResult}
                    pagination={false}
                  />
                </div>
              }
              </Col>
            </Row>
          }
        </div>
      </div>
    );
  }
}

export default withRouter(LocaleChurches);
