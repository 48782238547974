import React, { Component } from 'react';
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import {
  Input, Button, Form, Row, Col, PageHeader
} from 'antd';

import amsAPI from '../../../apis/amsAPI';

import 'antd/dist/antd.css';
import './Setting.css';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

class NewSetting extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  state = {
    setting: {},
  };

  handleSubmit = async e => {
    e.preventDefault();
    const { setting } = this.state;
    await amsAPI.fetchUrl(`/api/settings`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({...setting}),
    });
    this.props.history.push('/system/settings');
  }

  updateInfo = async (updatedInfo) => {
    const currentInfo = this.state.setting;
    this.setState({
      setting: { ...currentInfo, ...updatedInfo }
    });
  };

  render() {
    return (
      <PageHeader>
        <div className="wrap">
          <div className="extraContent">
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12}>
                <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                  <Form.Item
                    label="Name:"
                  >
                    <Input
                      onChange={e => this.updateInfo({ name: e.target.value })}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Code:"
                  >
                    <Input
                      onChange={e => this.updateInfo({ code: e.target.value })}
                    />
                  </Form.Item>
                  <Form.Item {...tailFormItemLayout}>
                    <Button type="primary" htmlType="submit">Create</Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </div>
        </div>
      </PageHeader>
    );
  }
}

export default withRouter(NewSetting);
