import React, { Component } from 'react';
import {
  Button, Col, Form, Row, Input, PageHeader, message,
} from 'antd';
import ReactGA from 'react-ga';

import amsAPI from '../../apis/amsAPI';

import 'antd/dist/antd.css';
import './Home.css';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

class NewCountry extends Component {
  state = {
    record: {},
    churchDivisions: [],
    churchDistricts: [],
    loading: false,
    submitting: false,
  };

  updateRecord = async (updatedInfo) => {
    const currentInfo = this.state.record;
    this.setState({
      record: { ...currentInfo, ...updatedInfo }
    });
  };

  handleSubmit = async (e) => {
    ReactGA.event({
      category: 'Button Click',
      action: 'submit new gathering type'
    });

    e.preventDefault();
    const { record } = this.state;
    this.setState({ submitting: true });
    amsAPI.fetchUrl(`/ams/gathering_types`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
      },
      body: JSON.stringify({...record}),
    })
    .then(async res => {
      if (res.status === 200) {
        const response = await res.json();
        if (!response.error_id) {
          message.success('Successfully added.');
          this.setState({ submitting: false });
          this.props.history.push(`/gathering_types`);
        }
      } else {
        const error = new Error(res.error);
        throw error;
      }
    })
    .catch(err => {
      console.error(err);
      this.setState({ submitting: false });
      message.error('Error submitting.');
    });
  };

  render() {
    const { record } = this.state;
    const { name } = record;
    const disableSubmit = !name;

    return (
      <PageHeader>
        <div className="wrap">
          <div className="extraContent">
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12}>
                <Form {...formItemLayout}>
                  <Form.Item label="Name:">
                    <Input
                      onChange={e => this.updateRecord({ name: e.target.value })}
                    />
                  </Form.Item>
                  <Form.Item label="Code:">
                    <Input
                      onChange={e => this.updateRecord({ code: e.target.value })}
                    />
                  </Form.Item>
                  <Form.Item {...tailFormItemLayout}>
                    <Button block type="primary"
                      loading={this.state.submitting}
                      onClick={this.handleSubmit}
                      disabled={disableSubmit}
                    >
                      {"Submit"}
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </div>
        </div>
      </PageHeader>
    );
  }
}

export default NewCountry;
