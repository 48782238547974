import React, { Component } from 'react';
import {
  Button, Col, Form, Row, message, Input,
  Radio, Steps,
} from 'antd';
import { withTranslation } from 'react-i18next';
import ReactGA from 'react-ga';

import _ from 'lodash';
import querystring from 'querystring';

import amsAPI from '../../apis/amsAPI';

import 'antd/dist/antd.css';
import './Form.css';

const { Step } = Steps;

let timeout;
let currentValue;

function fetch(value, callback) {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = value;

  async function fake() {
    const str = querystring.encode({
      q: value,
    });
    amsAPI.getUrl(`/ams/members?${str}`)
      .then(async response => {
        const body = await response.json();
        if (response.status !== 200) throw Error(body.message);
        return body;
      })
      .then(d => {
        if (currentValue === value) {
          callback(d.members);
        }
      });
  }

  timeout = setTimeout(fake, 300);
}

class MemberRegistration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recordId: '',
      current: 0,
      receiptNumber: '',
      mode: 'new',
      members: [],
      localeChurches: [],
      countries: [],
      history: [],
      stepStatus: [],
      submitting: false,
      stepInfo: {},
    };
  }

  componentDidMount() {
    this.getRequiredInfoFromAPI();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.getRequiredInfoFromAPI();
    }
  }

  getRequiredInfoFromAPI = async () => {
    this.callApi('/ams/locale_churches')
      .then(res => {
        this.setState({ localeChurches: res.data })
      });
  }

  callApi = async (url) => {
    const response = await amsAPI.getUrl(url)
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  getStepData = async (_id, step) => {
    let steps = ['personal','children','church_info','education','employment','business','medical'];
    console.log('getStepData', steps[step]);
    let apiUrl = `/api/candidates/${_id}/${steps[step]}`;
    this.callApi(apiUrl)
      .then(res => {
        this.setState({ stepInfo: res.data })
        if (steps[step] === "children" && res.data.children.length) {
          this.setState({ childrenInfo: res.data.children })

        } else if (steps[step] === "employment" && res.data.employmentRecords.length) {
          this.setState({ employmentInfo: res.data.employmentRecords })

        } else if (steps[step] === "medical" && res.data.medicalRecords.length) {
          this.setState({ medicalInfo: res.data.medicalRecords })
        }
        return Promise.resolve(0)
      });
  }

  getRegistrationInfo = () => {
    const { referenceNumber } = this.state;
    const query = { referenceNumber };
    const url = `/api/candidates?${querystring.encode(query)}`
    this.callApi(url)
      .then(res => {
        if (res.data.length) {
          const record = res.data[0];
          this.getStepData(record._id, 0);
        } else {
          message.error("Reference number not found!")
          this.setState({ referenceNumber: "" })
        }
      })
  }

  handleSubmit = async (e, step, isNext) => {
    ReactGA.event({
      category: 'Button Click',
      action: 'submit candidate registration'
    });

    e.preventDefault();
    this.setState({ submitting: true });
    const { stepInfo } = this.state;

    let method = ""
    let apiUrl = ""
    if (step === 0 && _.isEmpty(stepInfo.referenceNumber)) {
      method = "POST";
      apiUrl = "/api/candidates";
    } else {
      let steps = ['personal','children','church_info','education','employment','business','medical'];
      method = "PATCH"
      apiUrl = `/api/candidates/${stepInfo._id}/${steps[step]}`;
    }

    let payload = {}
    if (step === 1) {
      payload = { children: this.state.childrenInfo };
    } else {
      payload = { ...stepInfo }
    }
    amsAPI.fetchUrl(apiUrl, {
      method: method,
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
    .then(async res => {
      if (res.status === 200) {
        const response = await res.json();
        if (!_.isEmpty(response)) {
          const { _id } = response.data;
          this.setState({ submitting: false });
          let { hasChildren, hasBusiness } = stepInfo;
          if (isNext) {
            if ((step === 0 && !hasChildren)
              || (step === 4 && !hasBusiness)) {
                step = step + 1
            }
            step = step + 1
          } else {
            if ((step === 2 && !hasChildren)
              || (step === 6 && !hasBusiness)) {
                step = step - 1
            }
            step = step - 1;
          }
          console.log('step', step)
          if (step === 7) {
            this.props.history.push(`/candidate_registration_receipt`);
          } else {
            this.getStepData(_id, step);
          }
        }
      } else {
        const error = new Error(res.error);
        throw error;
      }
    })
    .catch(err => {
      console.error(err);
      this.setState({ submitting: false });
      message.error('Error submitting candidate information.');
    });
  };

  handleSearch = value => {
    if (value) {
      fetch(value, data => {
        this.setState({ members: data })
      });
    } else {
      this.setState({ members: [] });
    }
  };

  handleChange = value => {
    this.updateStepInfo({ reportedBy: value })
  };

  updateStepInfo = async (updatedInfo) => {
    const currentInfo = this.state.stepInfo;
    this.setState({
      stepInfo: { ...currentInfo, ...updatedInfo }
    });
  }

  next = (e) => {
    let { current } = this.state;
    this.handleSubmit(e, current, true)
    current = current + 1
    this.setState({ current });
  }

  prev = (e) => {
    let { current } = this.state;
    this.handleSubmit(e, current, false)
    current = current - 1;
    this.setState({ current });
  }

  render() {
    const { history, current, stepInfo } = this.state;
    const {  t } = this.props;

    let modResult = [];
    let i = 0;
    history.forEach(item => {
      i++;
      modResult.push({ ...item, key: item._id, rowKey: { _id: item._id, rowNum: i } });
    });

    const steps = [
      {
        title: 'Registration',
        content:
        <div>
          <Row type="flex" justify="start">
            <Col xs={24} sm={24} md={24} lg={{span: 12, offset: 4 }} >
              <Form layout="horizontal"
                labelCol={ { xs: { span: 24 }, sm: { span: 10 }}}
                wrapperCol={{ xs: { span: 24 }, sm: { span: 12 }}}
              >
                <Form.Item label="Category">
                  <Radio.Group
                    onChange={e => {
                      const { value } = e.target;
                      this.setState({ mode: value, referenceNumber: null });
                      this.props.clearState();
                    }}
                    value={this.state.mode}
                  >
                    <Radio value={"new"}>New Registration</Radio>
                    <Radio value={"rereg"}>Re-registration</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item label="Reference Number">
                  <Input
                    onChange={e => this.updateStepInfo({ referenceNumber: e.target.value })}
                    value={stepInfo.referenceNumber}
                  />
                  <Button
                    onClick={this.getRegistrationInfo}
                    disabled={!stepInfo.referenceNumber}
                  >Retrieve</Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
          <Row type="flex" justify="start">
            <Col xs={24} sm={24} md={24} lg={{span: 12, offset: 4 }}>
              <Form layout="horizontal"
                labelCol={ { xs: { span: 24 }, sm: { span: 10 }}}
                wrapperCol={{ xs: { span: 24 }, sm: { span: 12 }}}
              >
                <Form.Item
                  label="Last Name"
                >
                  <Input
                    onChange={(e) => this.updateStepInfo({ lastName: e.target.value })}
                    value={stepInfo.lastName}
                  />
                </Form.Item>
                <Form.Item
                  label="First Name"
                >
                  <Input
                    onChange={(e) => this.updateStepInfo({ firstName: e.target.value })}
                    value={stepInfo.firstName}
                  />
                </Form.Item>
                <Form.Item
                  label="Middle Name"
                >
                  <Input
                    onChange={(e) => this.updateStepInfo({ middleName: e.target.value })}
                    value={stepInfo.middleName}
                  />
                </Form.Item>
                <Form.Item
                  label="Gender"
                >
                  <Radio.Group
                    onChange={(e) => this.updateStepInfo({ gender: e.target.value })}
                    value={stepInfo.gender}
                  >
                    <Radio value={"male"}>Male</Radio>
                    <Radio value={"female"}>Female</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item label="Email Address">
                  <Input
                    onChange={(e) => this.updateStepInfo({ email: e.target.value })}
                    value={stepInfo.email}
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </div>
        ,
      },
      {
        title: 'Confirmation',
        content:
          <Row type="flex" justify="start">
            <Col xs={24} sm={24} md={24} lg={{span: 12, offset: 4 }}>
            </Col>
          </Row>
        ,
      },
      {
        title: 'Receipt',
        content:
          <Row type="flex" justify="start">
            <Col xs={24} sm={24} md={24} lg={{span: 12, offset: 4 }}>
            </Col>
          </Row>
        ,
      },
    ];

    const disableSubmit = false;
    return (
      <div className="wrap">
        <div className="extraContent">
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={3}>
              <h3>{t("Member Registration")}</h3>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={24}>
              <Steps current={current}>
                {steps.map(item => (
                  <Step key={item.title} title={item.title} />
                ))}
              </Steps>
            </Col>
          </Row>
          <Row type="flex" justify="start" style={{ marginTop: 5, marginBottom: 5 }}>
            <Col xs={24} sm={24} md={24} lg={{span: 4, offset: 13 }}>
              <div className="steps-action">
                {current > 0 && (
                  <Button onClick={this.prev}>
                    Previous
                  </Button>
                )}
                {current < steps.length - 1 && (
                  <Button
                    type="primary"
                    style={current > 0 ? { marginLeft: 8 } : { marginLeft: 0 }}
                    onClick={this.next}
                  >
                    Next
                  </Button>
                )}
                {current === steps.length - 1 && (
                  <Button
                    type="primary"
                    style={{ marginLeft: 8 }}
                    loading={this.state.submitting}
                    onClick={this.next}
                    disabled={disableSubmit}
                  >
                    {t("Submit")}
                  </Button>
                )}
              </div>
            </Col>
          </Row>
          <Row type="flex" justify="start">
            <Col xs={24} sm={24} md={24} lg={24}>
              {steps[current].content}
            </Col>
          </Row>
          <Row type="flex" justify="start">
            <Col xs={24} sm={24} md={24} lg={{span: 4, offset: 13 }}>
              <div className="steps-action">
                {current > 0 && (
                  <Button onClick={this.prev}>
                    Previous
                  </Button>
                )}
                {current < steps.length - 1 && (
                  <Button
                    type="primary"
                    style={current > 0 ? { marginLeft: 8 } : { marginLeft: 0 }}
                    onClick={this.next}
                  >
                    Next
                  </Button>
                )}
                {current === steps.length - 1 && (
                  <Button
                    type="primary"
                    style={{ marginLeft: 8 }}
                    loading={this.state.submitting}
                    onClick={this.handleSubmit}
                    disabled={disableSubmit}
                  >
                    {t("Submit")}
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default withTranslation()(MemberRegistration);