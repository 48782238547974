import React, { Component } from 'react';
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import {
  Input, Button, Form, Row, Col, PageHeader, Spin, Select, Switch,
} from 'antd';

import * as constants from '../../../helpers/constants';
import amsAPI from '../../../apis/amsAPI';

import 'antd/dist/antd.css';
import './Setting.css';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

class EditSetting extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  state = {
    setting: {},
    loading: false,
  };

  componentDidMount() {
    this.getRequiredInfoFromAPI();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.getRequiredInfoFromAPI();
    }
  }

  getRequiredInfoFromAPI = async () => {
    this.setState({ loading: true });
    this.getSetting()
      .then(res => {
        this.setState({ setting: res.data, loading: false });
      })
      .catch(err => console.log(err));
  }

  handleSubmit = async e => {
    e.preventDefault();
    const { setting } = this.state;
    const { _id } = this.props.match.params;
    await amsAPI.fetchUrl(`/api/settings/${_id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({...setting}),
    });
    this.props.history.push('/system/settings');
  }

  getSetting = async () => {
    const { _id } = this.props.match.params;
    const response = await amsAPI.getUrl(`/api/settings/${_id}`);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  updateInfo = async (updatedInfo) => {
    const currentInfo = this.state.setting;
    this.setState({
      setting: { ...currentInfo, ...updatedInfo }
    });
  };

  render() {
    const scopes = Object.entries(constants.scopes);
    const { setting, loading } = this.state;
    if (loading) {
      return (
        <div className="wrap">
          <div className="extraContent">
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12} style={{ textAlign: "center" }}>
                <Spin size="large" />
              </Col>
            </Row>
          </div>
        </div>
      )
    }

    return (
      <PageHeader>
        <div className="wrap">
          <div className="extraContent">
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12}>
                <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                  <Form.Item
                    label="Name"
                  >
                    <Input
                      defaultValue={setting.name}
                      onChange={(e) => this.updateInfo({ name: e.target.value })}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Code"
                  >
                    <Input
                      defaultValue={setting.code}
                      onChange={e => this.updateInfo({ code: e.target.value })}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Module"
                  >
                    <Input
                      defaultValue={setting.module}
                      onChange={e => this.updateInfo({ module: e.target.value })}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Pathname"
                  >
                    <Input
                      defaultValue={setting.pathname}
                      onChange={e => this.updateInfo({ pathname: e.target.value })}
                    />
                  </Form.Item>
                  <Form.Item label="Scopes">
                    <Select
                      placeholder="Please select scopes"
                      dropdownMatchSelectWidth={false}
                      onChange={value => this.updateInfo({ scopes: value })}
                      value={setting.scopes}
                      allowClear={true}
                      mode={"multiple"}
                    >
                      {scopes.map(([id, name]) =>
                        <Option key={id} value={id}>{name}</Option>
                      )}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Enabled"
                  >
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      defaultChecked={setting.enabled}
                      onChange={(checked) => this.updateInfo({ enabled: checked })}
                    />
                  </Form.Item>
                  <Form.Item {...tailFormItemLayout}>
                    <Button type="primary" htmlType="submit">Update</Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </div>
        </div>
      </PageHeader>
    );
  }
}

export default withRouter(EditSetting);