import React, { Component } from 'react';
import {
  Form, Button, DatePicker, Select, Row, Col, Spin, Switch, Input, message
} from 'antd';

import moment from 'moment';
import _ from 'lodash';

import amsAPI from '../../apis/amsAPI';
import disablePastDates from '../../helpers/functions';
import * as constants from '../../helpers/constants';

import 'antd/dist/antd.css';
import './Gathering.css';

const Option = Select.Option;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 12,
      offset: 6,
    },
  },
};

class EditGathering extends Component {
  state = {
    name: '',
    type: '',
    scope: '',
    gatheringTypes: [],
    startDateTime: null,
    endDateTime: null,
    gatheringLink: '',
    isActive: false,
    isDisplayLocaleLinks: false,
    localeChurches: [],
    localeLinks: [],
  };

  componentDidMount() {
    this.getRequiredInfoFromAPI();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.getRequiredInfoFromAPI();
    }
  }

  getRequiredInfoFromAPI = async () => {
    this.setState({ loading: true });
    this.callApi()
      .then(res => this.setState({ gatheringTypes: res.data, loading: false }))
      .catch(err => console.log(err));
    this.getGathering()
      .then(res => {
        const {
          name, type, startDateTime, endDateTime,
          active, gatheringLink, scope, channels,
        } = res.gathering;

        this.setState({
          name, type, startDateTime, endDateTime, scope, channels,
          isActive: active,
          gatheringLink,
          loading: false,
        });
      })
      .catch(err => console.log(err));
  }

  getGathering = async () => {
    const _id = this.props.location.pathname.split('/')[2];
    const response = await amsAPI.getUrl(`/api/gatherings/${_id}`)
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  callApi = async (query) => {
    this.setState({ loading: true });
    const response = await amsAPI.getUrl(`/api/gathering_types`);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  handleGatheringChange = name => {
    this.setState({ name: name })

    let defaultChannels = [];
    const zoomAndLineOnly = [ "nyr", "pmws", "pm", "ws", "pbb", "spbb", "ls", "tg", "spm" ];
    if (zoomAndLineOnly.includes(name)) {
      defaultChannels = ['zoom', 'others'];
    } else if (name === "sbk") {
      defaultChannels = [ 'zoom', 'mcgi.tv', 'instagram', 'youtube', 'satellite' ];
    } else if (name === "aods") {
      defaultChannels = [ "zoom" ];
    } else {
      defaultChannels = ['zoom', 'youtube', 'facebook', 'mcgi.tv', 'instagram', 'others'];
    }

    this.setState({ channels: defaultChannels });
  }

  handleSubmit = async e => {
    e.preventDefault();
    const _id = this.props.location.pathname.split('/')[2];
    await amsAPI.fetchUrl(`/ams/gatherings/${_id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: this.state.name,
        type: this.state.type,
        scope: this.state.scope,
        startDateTime: this.state.startDateTime,
        endDateTime: this.state.endDateTime,
        channels: this.state.channels,
        gatheringLink: this.state.gatheringLink,
        active: this.state.isActive ? true : false,
        selectedLocaleChurches: this.state.selectedLocaleChurches,
      }),
    });
    message.success("Gathering successfully updated.");
    this.props.history.push('/gatherings');
  };

  render() {
    const {
      name, type, isActive, startDateTime, endDateTime, gatheringLink,
      loading, gatheringTypes, scope, channels,
    } = this.state;
    const disableSubmit = !name || !type || !scope || _.isEmpty(channels) || !startDateTime;

    if (loading) {
      return (
        <div className="wrap">
          <div className="extraContent">
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12} style={{ textAlign: "center" }}>
                <Spin size="large" />
              </Col>
            </Row>
          </div>
        </div>
      )
    }

    const gatheringEntries = [];
    gatheringTypes.forEach(item => {
      gatheringEntries.push([item.code, item.name]);
    })
    const replayOrLive = Object.entries(constants.replayOrLive);
    const scopes = Object.entries(constants.scopes);
    const channelEntries = Object.entries(constants.channels);

    return (
      <div className="wrap">
        <div className="extraContent">
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={18}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <h2>Gathering Form</h2>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={18}>
              <Form {...formItemLayout}>
                <Form.Item label="Gathering:">
                  <Select
                    showSearch
                    placeholder="Select a gathering"
                    dropdownMatchSelectWidth={false}
                    style={{ width: 240 }}
                    onChange={this.handleGatheringChange}
                    value={name}
                  >
                    {gatheringEntries.map(([id, name]) =>
                      <Option key={id} value={id}>{name}</Option>
                    )}
                  </Select>
                </Form.Item>
                <Form.Item label="Type:">
                  <Select
                    showSearch
                    placeholder="Select a gathering type"
                    dropdownMatchSelectWidth={false}
                    style={{ width: 240 }}
                    onChange={value => this.setState({ type: value })}
                    value={type}
                  >
                    {replayOrLive.map(([id, name]) =>
                      <Option key={id} value={id}>{name}</Option>
                    )}
                  </Select>
                </Form.Item>
                <Form.Item label="Scope">
                  <Select
                    dropdownMatchSelectWidth={false}
                    style={{ width: 240 }}
                    onChange={value => this.setState({ scope: value })}
                    value={scope}
                  >
                    {scopes.map(([id, name]) =>
                      <Option key={id} value={id}>{name}</Option>
                    )}
                  </Select>
                </Form.Item>
                <Form.Item label="Start Date:">
                  <DatePicker
                    showToday
                    format="YYYY-MM-DD h:mm a"
                    showTime={{ use12Hours: true, format: "h:mm a" }}
                    placeholder="Start Date Time"
                    onChange={(value) => this.setState({ startDateTime: value })}
                    disabledDate={disablePastDates}
                    value={startDateTime ? moment(startDateTime) : null}
                  />
                </Form.Item>
                <Form.Item label="End Date:">
                  <DatePicker
                    showToday
                    format="YYYY-MM-DD h:mm a"
                    showTime={{ use12Hours: true, format: "h:mm a" }}
                    placeholder="End Date Time"
                    onChange={(value) => this.setState({ endDateTime: value })}
                    disabledDate={disablePastDates}
                    value={endDateTime ? moment(endDateTime) : null}
                  />
                </Form.Item>
                <Form.Item label="Channels">
                  <Select
                    placeholder="Please select channels"
                    dropdownMatchSelectWidth={false}
                    style={{ width: 240 }}
                    onChange={(value) => this.setState({ channels: value })}
                    allowClear={true}
                    mode={"multiple"}
                    value={channels}
                  >
                    {channelEntries.map(([id, name]) =>
                      <Option key={id} value={id}>{name}</Option>
                    )}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Gathering Link:"
                >
                  <Input
                    onChange={(e) => this.setState({ gatheringLink: e.target.value })}
                    value={gatheringLink}
                    style={{ width: 500 }}
                  />
                </Form.Item>
                <Form.Item label="Is active?">
                  <Switch
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    checked={isActive}
                    onChange={(checked) => this.setState({ isActive: checked })}
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}>
              <Form {...formItemLayout}>
                <Form.Item {...tailFormItemLayout}>
                  <Button
                    block
                    onClick={this.handleSubmit}
                    type="primary"
                    htmlType="submit"
                    disabled={disableSubmit}
                  >Update</Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default EditGathering;
