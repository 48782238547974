import React, { Component } from 'react';
import { Form, Select } from 'antd';
import 'antd/dist/antd.css';
import './CreateForm.css';

const Option = Select.Option;

class MusicMinistryMemberForm extends Component {
  render() {
    const { member } = this.props;
    return (
      <div>
        <Form.Item
          label="Member Type:"
        >
          <Select
            dropdownMatchSelectWidth={false}
            onChange={value => this.props.updateMember({ memberType: value })}
            defaultValue={member.memberType}
          >
            <Option value="regular">Regular</Option>
            <Option value="aspirant">Aspirant</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Voice Designation:"
        >
          <Select
            dropdownMatchSelectWidth={false}
            onChange={value => this.props.updateMember({ voiceDesignation: value })}
            defaultValue={member.voiceDesignation}
          >
            <Option value="s">Soprano</Option>
            <Option value="a">Alto</Option>
            <Option value="t">Tenor</Option>
            <Option value="b">Bass</Option>
          </Select>
        </Form.Item>
      </div>
    );
  }
}

export default MusicMinistryMemberForm;
