import React, { Component } from 'react';
import {
  Button, Col, Form, Row, Input, PageHeader, message,
  Select,
} from 'antd';
import ReactGA from 'react-ga';

import amsAPI from '../../../apis/amsAPI';

import 'antd/dist/antd.css';
import './Home.css';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const { Option } = Select;

class NewChurchDistrict extends Component {
  state = {
    record: {},
    churchDivisions: [],
    loading: false,
    submitting: false,
  };

  componentDidMount() {
    this.getRequiredInfoFromAPI();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.getRequiredInfoFromAPI();
    }
  }

  getRequiredInfoFromAPI = async () => {
    this.setState({ loadingChurchDivisions: true });
    this.callApi('/ams/church_divisions')
      .then(res => {
        this.setState({ churchDivisions: res.data, loadingChurchDivisions: false })
      });
  }

  callApi = async (url) => {
    const response = await amsAPI.getUrl(url)
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  updateRecord = async (updatedInfo) => {
    const currentInfo = this.state.record;
    this.setState({
      record: { ...currentInfo, ...updatedInfo }
    });
  };

  handleSubmit = async (e) => {
    ReactGA.event({
      category: 'Button Click',
      action: 'submit new church district'
    });

    e.preventDefault();
    const { record } = this.state;
    this.setState({ submitting: true });
    amsAPI.fetchUrl(`/api/church_districts`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
      },
      body: JSON.stringify({...record}),
    })
    .then(async res => {
      if (res.status === 200) {
        const response = await res.json();
        if (!response.error_id) {
          message.success('Successfully added.');
          this.setState({ submitting: false });
          this.props.history.push(`/church_districts`);
        }
      } else {
        const error = new Error(res.error);
        throw error;
      }
    })
    .catch(err => {
      console.error(err);
      this.setState({ submitting: false });
      message.error('Error submitting.');
    });
  };

  render() {
    const { record, churchDivisions } = this.state;
    const { name } = record;
    const disableSubmit = !name;
    return (
      <PageHeader>
        <div className="wrap">
          <div className="extraContent">
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12}>
                <Form {...formItemLayout}>
                  <Form.Item label="Name:">
                    <Input
                      onChange={e => this.updateRecord({ name: e.target.value })}
                    />
                  </Form.Item>
                  <Form.Item label="Division:">
                    <Select
                      placeholder="Select a division"
                      dropdownMatchSelectWidth={false}
                      onChange={value => this.updateRecord({ churchDivision: value })}
                    >
                      {churchDivisions.map(item => {
                        return <Option key={item._id} value={item._id}>{item.name}</Option>
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item {...tailFormItemLayout}>
                    <Button block type="primary"
                      loading={this.state.submitting}
                      onClick={this.handleSubmit}
                      disabled={disableSubmit}
                    >
                      {"Submit"}
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </div>
        </div>
      </PageHeader>
    );
  }
}

export default NewChurchDistrict;
