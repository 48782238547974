import React, { Component } from 'react';
import { Button, Col, Form, Icon, Row, Upload, message, Table } from 'antd';
import * as CSV from 'csv-string';

import amsAPI from '../../apis/amsAPI';
import * as constants from '../../helpers/constants';

import 'antd/dist/antd.css';
import './Upload.css';

class UploadLocaleChurches extends Component {
  state = {
    fileList: [],
    uploading: false,
  };

  handleUpload = () => {
    const { fileList } = this.state;
    this.setState({ uploading: true });

    const reader = new FileReader();
    reader.readAsText(fileList[0]);
    reader.onload = this.registerMembers;
  };

  registerMembers = (e) => {
    const csvData = e.target.result;
    const allRows = CSV.parse(csvData);

    amsAPI.fetchUrl(`/ams/locale_churches/upload`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        data: allRows.slice(1)
      }),
    })
    .then(async res => {
      this.setState({ loading: false, uploading: false })
      if (res.status === 200) {
        const response = await res.json();
        message.success(`${response.message}`);
        this.props.history.push('/locale_churches');
      } else {
        const error = new Error(res.error);
        throw error;
      }
    })
    .catch(err => {
      this.setState({ loading: false, uploading: false })
      message.error('Error adding locale churches.');
    });  
  }

  render() {

    const { uploading, fileList } = this.state;
    const props = {
      accept: ".csv",
      onRemove: file => {
        this.setState(state => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
      },
      beforeUpload: file => {
        this.setState(state => ({
          fileList: [file],
        }));
        return false;
      },
      fileList,
    };

    return (
      <div className="wrap">
        <div className="extraContent">
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}>
              <h3>Please upload the CSV file containing the list of locale churches you want to register.</h3>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}>
              <Form {...constants.formItemLayout}>
                <Form.Item label="File:">
                <Upload {...props}>
                  <Button>
                    <Icon type="upload" /> Select File
                  </Button>
                </Upload>
                <Button
                  type="primary"
                  onClick={this.handleUpload}
                  disabled={fileList.length === 0}
                  loading={uploading}
                  style={{ marginTop: 16 }}
                >
                  {uploading ? 'Uploading' : 'Start Upload'}
                </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}>
              <div>Please make sure the CSV (Comma Separated Value) file is in the following format:</div>
              <Table pagination={false} bordered dataSource={constants.dataSource} columns={constants.columns} />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default UploadLocaleChurches;
