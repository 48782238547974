import React, { Component } from 'react';
import {
  Form, Select, Row, Col, PageHeader
} from 'antd';

import MusicMinistryMemberForm from './MusicMinistryMemberForm';

import amsAPI from '../../apis/amsAPI';
import 'antd/dist/antd.css';
import './CreateForm.css';

const Option = Select.Option;

class RegisterMember extends Component {
  state = {
    response: [],
    name: '',
    ministry: '',
    memberType: '',
    voiceDesignation: '',
    location: '',
    responseToPost: '',
    collapsed: false,
    selectedMinistry: '',
    ministries: [],
  };

  componentDidMount() {
    this.getMinistries()
      .then(res => {
        this.setState({ ministries: res.ministries })
      })
      .catch(err => console.log(err));  
  }

  getMinistries = async () => {
    const response = await amsAPI.getUrl('/ams/ministries')
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  handleSubmit = async e => {
    e.preventDefault();
    const { localeId } = this.props.match.params;
    const response = await amsAPI.fetchUrl(`/ams/locale_churches/${localeId}/members`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        name: this.state.name,
        memberType: this.state.memberType,
        voiceDesignation: this.state.voiceDesignation,
      }),
    });
    const body = await response.text();
    this.setState({ responseToPost: body });
  };

  handleMinsitrySelect = async (value) => {
    this.setState({ selectedMinistry: value });
  };

  render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    const { selectedMinistry, ministries } = this.state;

    return (
      <PageHeader>
        <div className="wrap">
          <div className="extraContent">
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12}>
                <Form {...formItemLayout}>
                  <Form.Item label="Please select a ministry">
                    <Select
                        showSearch
                        placeholder="Select a ministry"
                        dropdownMatchSelectWidth={false}
                        onChange={(value) => this.setState({ selectedMinistry: value })}
                        value={selectedMinistry}
                      >
                        {ministries && ministries.map(ministry => {
                          return <Option key={ministry._id} value={ministry.name}>{ministry.name}</Option>
                        })}
                    </Select>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12}>
                {this.state.selectedMinistry === 'Music Ministry' && 
                  <MusicMinistryMemberForm />
                }
              </Col>
            </Row>
          </div>
        </div>
      </PageHeader>
    );
  }
}

export default RegisterMember;
