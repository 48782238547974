import React, { Component } from 'react';
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { NavLink } from 'react-router-dom';
import {
  Button, Col, Row, Spin, Table, Popconfirm, message,
} from 'antd';

import amsAPI from '../../apis/amsAPI';

import 'antd/dist/antd.css';
import './Ministry.css';

class Ministries extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      response: [],
      loading: false,
    };

    this.columns = [
      {
        title: 'No',
        dataIndex: 'rowKey',
        key: 'rowKey.rowNum',
        render: rowKey =>
          <NavLink to={`/${rowKey.location}/${rowKey._id}/edit`}>
            {rowKey.rowNum}
          </NavLink>
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: name =>
          <span>{name}</span>
      },
      {
        title: 'Code',
        dataIndex: 'code',
        key: 'code',
        render: code =>
          <span>{code}</span>
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <span>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => this.handleDelete(record.rowKey.location, record._id)}
            >
              <Button type="link" icon="delete" />
            </Popconfirm>
          </span>
        ),
      },
    ];
  }

  componentDidMount() {
    const location = this.props.location.pathname.split('/')[1];
    this.callApi()
      .then(res => this.setState({ response: res[location], loading: false }))
      .catch(err => console.log(err));
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      const location = this.props.location.pathname.split('/')[1];
      this.callApi()
        .then(res => this.setState({ response: res[location], loading: false }))
        .catch(err => console.log(err));
    }
  }

  callApi = async () => {
    this.setState({ loading: true });
    const location = this.props.location.pathname.split('/')[1];
    const response = await amsAPI.getUrl(`/ams/${location}`);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  handleDelete = async (path, id) => {
    await amsAPI.fetchUrl(`/api/${path}/${id}`, {
      method: 'DELETE',
    });
    message.success("Successfully removed.")
    this.props.history.push(`/${path}`);
  };

  render() {
    const location = this.props.location.pathname.split('/')[1];
    const { response, loading } = this.state;
    let modResult = [];
    if (response && response.length > 0) {
      let i = 0;
      response.forEach(item => {
        i++;
        modResult.push({
          ...item,
          key: i,
          rowKey: { _id: item._id, rowNum: i, location }
        });
      });
    }

    if (loading) {
      return (
        <div className="wrap">
          <div className="extraContent">
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12} style={{ textAlign: "center" }}>
                <Spin size="large" />
              </Col>
            </Row>
          </div>
        </div>
      )
    }

    return (
      <div className="wrap">
        <div className="extraContent">
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}>
            {(response && response.length === 0) ?
              <div>
                <h3>{`Sorry, but there's are no registered ${location}.`}</h3>
              </div>
            :
              <div>
                <h3>{`Here's are the list of ${location}`}</h3>
                <Table pagination={false} columns={this.columns} dataSource={modResult} />
              </div>
            }
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default withRouter(Ministries);
