import moment from 'moment';

export default function disablePastDates(current) {
  // Can not select days before today and today
  return current && current < moment().subtract(1, 'days').endOf('day');
}

export function arrayBufferToBase64(buffer) {
  var binary = '';
  var bytes = [].slice.call(new Uint8Array(buffer));
  bytes.forEach((b) => binary += String.fromCharCode(b));
  return window.btoa(binary);
}