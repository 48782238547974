import React, { Component } from 'react';
import PropTypes from "prop-types";
import { Route } from 'react-router-dom';
import { withRouter } from "react-router";
import { NavLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Button, Col, Layout, Row, Menu, Icon, message, Select, Form } from 'antd';
import _ from 'lodash';

import ReactGA from 'react-ga';
import { GoogleLogout, GoogleLogin } from 'react-google-login';

import UrlPattern from 'url-pattern';

import {
  Home, DivisionHome, UploadHome, ReportsHome, GatheringsHome, MinistryHome,
  SystemHome, ReportsHomeByGathering,
} from './homes';
import {
  AttendanceReportByGathering, AttendanceReportByGatheringElders,
} from './reports';
import {
  DivisionAttendanceReport, DivisionAttendanceReceipt, DivisionAttendanceReports,
  DivisionAttendanceReportHistory,
} from './division_attendance_report';
import { MinistryAttendance, MinistryAttendanceReceipt } from './ministry_attendance';
import { Login } from './form';
import {
  RegisterMember, MemberForm, NewMember, MembersList, MemberHistory
} from './member';
import {
  CreateGathering, EditGathering,
  Gatherings, GatheringAttendees,
} from './gathering'
import {
  NewGatheringType, EditGatheringType, GatheringTypes,
} from './gathering_types'
import { NewChurchDivision, EditChurchDivision, ChurchDivisions } from './divisions/church_division';
import { NewChurchDistrict, EditChurchDistrict, ChurchDistricts } from './divisions/church_district';
import { NewCountry, EditCountry, Countries } from './divisions/country';
import { LocaleChurches, NewLocaleChurch, EditLocaleChurch, LocaleChurchPopulation } from './locale_churches';
import { UploadMembers, UploadCountries, UploadLocaleChurches } from './uploads';
import { MemberRegistration, RegistrationReceipt } from './registration';
import { CreateMinistry, EditMinistry, Ministries } from './ministry';
import { Roles, NewRole, EditRole } from './system/roles';
import { Settings, NewSetting, EditSetting } from './system/settings';
import { Unauthorized } from './errors';

import amsAPI from '../apis/amsAPI';

import 'antd/dist/antd.css';
import './ContentWrapper.css';

ReactGA.initialize('UA-146791855-1');

const { Option } = Select;
const { Content, Header, Sider } = Layout;

const WrappedDivisionAttendanceReport = Form.create({ name: 'division_attendance' })(DivisionAttendanceReport);

const PersonCircleSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-circle" viewBox="0 0 16 16">
    <path d="M13.468 12.37C12.758 11.226 11.195 10 8 10s-4.757 1.225-5.468 2.37A6.987 6.987 0 0 0 8 15a6.987 6.987 0 0 0 5.468-2.63z"/>
    <path fillRule="evenodd" d="M8 9a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
    <path fillRule="evenodd" d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zM0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8z"/>
  </svg>
);
const PersonCircleIcon = props => <Icon component={PersonCircleSvg} {...props} theme="twoTone" twoToneColor="#eb2f96" />;

class DivisionWrapper extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  constructor(props){
    super(props);
    this.state = {
      receiptNumber: '',
      remittanceInfo: {},
      attendanceReport: {},
      userInfo: {},
      roles: [],
      isLocaleMembersPage: false,
      isSignedIn: false,
      isGoogleLogin: false,
      membersFilter: { },
      services: {
        ams: {
          isEnabled: _.isEmpty(process.env.REACT_APP_AMS_API) ? false : true,
          url: process.env.REACT_APP_AMS_API,
        },
        fin: {
          isEnabled: _.isEmpty(process.env.REACT_APP_FINANCE_API) ? false : true,
          url: process.env.REACT_APP_FINANCE_API,
        },
        sys: {
          isEnabled: _.isEmpty(process.env.REACT_APP_SYSTEM_API) ? false : true,
          url: process.env.REACT_APP_SYSTEM_API,
        },
        tic: {
          isEnabled: _.isEmpty(process.env.REACT_APP_TICKETS_API) ? false : true,
          url: process.env.REACT_APP_TICKETS_API,
        },
      },
    };
    this.goBack = this.goBack.bind(this);
    this.goToNew = this.goToNew.bind(this);
    this.goHome = this.goHome.bind(this);
    this.onLoginSuccess = this.onLoginSuccess.bind(this);
    this.onLoginFailed = this.onLoginFailed.bind(this);
    this.onLogoutSuccess = this.onLogoutSuccess.bind(this);
    this.setActiveGathering = this.setActiveGathering.bind(this);
    this.setActiveGatherings = this.setActiveGatherings.bind(this);
    this.setActiveActivity = this.setActiveActivity.bind(this);
    this.setReceiptNumber = this.setReceiptNumber.bind(this);
    this.setGatheringReceiptNumber = this.setGatheringReceiptNumber.bind(this);
    this.clearState = this.clearState.bind(this);
    this.changeLang = this.changeLang.bind(this);

 }

  goBack() {
    const pathname = this.props.location.pathname;
    const isSubHomePage = pathname.substring(1).match(
        /^(divisions)$/,
      );
    const pattern = new UrlPattern('/locale_reports/:id/edit');
    const isLocaleReportsEditPage = pattern.match(pathname);
    const isDivisionPage = pathname.substring(1).match(
      /^(church_divisions|church_districts|countries|locale_churches)$/,
    );

    if (isSubHomePage) {
      this.props.history.push("/");
    } else if (isDivisionPage) {
      this.props.history.push("/divisions");
    } else if (isLocaleReportsEditPage) {
      this.props.history.push("/locale_reports");
    } else {
      this.props.history.goBack();
    }
  }

  changeLang = value => {
    this.setState(prevState => ({ lang: value }));
    this.props.i18n.changeLanguage(value);
  };

  goToNew() {
    const pathname = this.props.location.pathname;
    const newUrl = pathname + '/new';
    this.props.history.push(newUrl);
  }

  goHome() {
    this.props.history.push("/");
  }

  onLoginSuccess = info => {
    amsAPI.fetchUrl(`/ams/login`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache'
      },
      body: JSON.stringify({
        email: info.profileObj.email,
      }),
    })
    .then(async res => {
      if (res.status === 200) {
        const body = await res.json();
        message.success("Successfully logged in.")
        this.setState({
          isSignedIn: true,
          isGoogleLogin: true,
          userInfo: {
            localeChurchId: body.localeChurchId,
            memberId: body._id,
            roles: body.roles,
            name: body.name,
            isAdmin: body.isAdmin,
            userMemberId: body._id,
          },
          localeChurchId: body.localeChurchId,
          userMemberId: body.id,
          roles: body.roles,
          err: null
        })
      } else {
        const error = new Error(res.error);
        throw error;
      }
    })
    .catch(err => {
      console.error(err);
      message.error('Error logging in.');
    });
  }

  setLogin = (info) => {
    this.setState({
      ...info,
      isSignedIn: true,
      err: null
    });
  }

  logoutUser = () => {
    this.setState({
      isSignedIn: false,
      isGoogleLogin: false,
    });
  }

  onLoginFailed = (err) => {
    console.log('login failed', err)
    this.setState({
      isSignedIn: false,
      error: err,
    })
  }

  onLogoutSuccess = () => {
    this.setState({
      isSignedIn: false,
    })
  }

  onLogoutFailed = (err) => {
    this.setState({
      isSignedIn: false,
      error: err,
    })
  }

  updateRemittanceInfo = async (updatedInfo) => {
    const currentInfo = this.state.remittanceInfo;
    this.setState({
      remittanceInfo: { ...currentInfo, ...updatedInfo }
    });
  };

  updateAttendanceReport = async (updatedInfo) => {
    const currentInfo = this.state.attendanceReport;
    this.setState({
      attendanceReport: { ...currentInfo, ...updatedInfo }
    });
  };

  setActiveGathering = (value) => {
    this.setState({ activeGathering: value });
  }

  setActiveGatherings = (value) => {
    this.setState({ activeGatherings: value });
  }

  setActiveActivity= (value) => {
    this.setState({ activeActivity: value });
  }

  setMode = (value) => {
    this.setState({ mode: value });
  }

  setChannel = (value) => {
    this.setState({ channel: value });
  }

  setOtherChannel = (value) => {
    this.setState({ otherChannel: value });
  }

  setMembers = (value) => {
    if (typeof value === "string") {
      this.setState({ selectedMembers: [value] });
    } else {
      this.setState({ selectedMembers: value });
    }
  }

  setReceiptNumber = (value) => {
    this.setState({ receiptNumber: value });
  }

  setGatheringReceiptNumber = (value) => {
    this.setState({ gatheringReceiptNumber: value });
  }

  setReceiptNumber = (value) => {
    this.setState({ receiptNumber: value });
  }

  clearState = () => {
    this.setState({
      attendanceReport: {},
      activeActivity: {},
      selectedMembers: [],
      channel: '',
      otherChannel: '',
      mode: '',
    });
  }

  changeLang = value => {
    this.setState(prevState => ({ lang: value }));
    this.props.i18n.changeLanguage(value);
  };

  setMembersFilter = async (updatedFilter) => {
    const currentFilter = this.state.membersFilter;
    this.setState({
      membersFilter: { ...currentFilter, ...updatedFilter }
    });
  };

  clearMembersFilter = () => {
    this.setState({ membersFilter: {  } });
  };

  render() {
    const pathname = this.props.location.pathname;
    const { isSignedIn, isGoogleLogin } = this.state;
    const isAdd = pathname.indexOf('new') >= 0;
    const isListPage = pathname.substring(1).match(
      /^(stores|locale_churches|gatherings|church_groups|ministries|members|system\/roles|system\/settings|links|bank_accounts|remittances|church_divisions|church_districts|countries|activities|gathering_types)$/,
    );
    const displayAddButton = isListPage;

    return (
      <Layout style={{ minHeight: '100vh' }}>
        <Sider
          collapsible
          collapsedWidth="0"
          defaultCollapsed={true}
        >
          <div className="logo" />
          <Menu
            theme="dark"
            mode="inline"
          >
            <Menu.Item key="0">
              <NavLink
                to={"/"}
              >
                <Icon type="home" />
                <span className="nav-text">Home</span>
              </NavLink>
            </Menu.Item>
            <Menu.Item key="2" style={{ display: "none"}}>
              <NavLink
                to={"/ministry_attendance/workers"}
              >
                <PersonCircleIcon />
                <span className="nav-text">Workers</span>
              </NavLink>
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout>
          <Header
            style={{
              position: 'fixed',
              zIndex: 1,
              width: '100%',
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <Menu
              selectable={false}
              theme="dark"
              mode="horizontal"
              style={{ lineHeight: '20px' }}
            >
              <Menu.Item
                key="2"
                style={{ padding: '0' }}
              >
                {(pathname !== "/") &&
                  <Button
                    type="default"
                    size="large"
                    onClick={this.goHome}
                  >
                    <Icon type="home" />
                  </Button>
                }
                {(pathname !== "/" && isSignedIn) &&
                  <Button
                    type="default"
                    size="large"
                    onClick={this.goBack}
                    style={{ marginLeft: "5px" }}
                  >
                    Back
                  </Button>
                }
              </Menu.Item>
            </Menu>
            <Menu
              selectable={false}
              theme="dark"
              mode="horizontal"
              style={{ lineHeight: '20px' }}
            >
              <Menu.Item
                key="1"
              >
                <Select
                  onChange={this.changeLang}
                  defaultValue="en"
                  style={{ marginRight: "10px" }}
                >
                  <Option key="en" value="en">English</Option>
                  <Option key="jp" value="jp">日本語</Option>
                </Select>
                {isSignedIn &&
                  <Button
                    type="default"
                    size="large"
                    disabled={isAdd}
                    onClick={this.goToNew}
                    style={
                      {
                        display: (displayAddButton) ? "inherit" : "none",
                        marginRight: '10px',
                      }
                    }
                  >Add</Button>
                }
                {(!isSignedIn && pathname !== "/") &&
                  <GoogleLogin
                    clientId={process.env.REACT_APP_CLIENT_ID}
                    buttonText="Login"
                    onSuccess={this.onLoginSuccess}
                    onFailure={this.onLoginFailed}
                    cookiePolicy={'single_host_origin'}
                  />
                }
                {(isSignedIn && isGoogleLogin) &&
                  <GoogleLogout
                    clientId={process.env.REACT_APP_CLIENT_ID}
                    buttonText="Logout"
                    onLogoutSuccess={this.onLogoutSuccess}
                    onFailure={this.onLogoutFailed}
                  />
                }
                {(isSignedIn && !isGoogleLogin) &&
                  <Button
                    type="default"
                    size="large"
                    onClick={this.logoutUser}
                  >Logout</Button>
                }
              </Menu.Item>
            </Menu>
          </Header>
          <Content style={{ padding: '0 50px', marginTop: 75 }}>
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={24}>
                <div style={{ padding: 24, background: '#fff', minHeight: 320 }}>
                  <Route exact path="/"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <Home
                          {...props}
                          localeChurchId={this.state.localeChurchId}
                          userInfo={this.state.userInfo}
                        />
                        :
                        <Login
                          {...props}
                          setLogin={this.setLogin}
                          onLoginSuccess={this.onLoginSuccess}
                          onLoginFailed={this.onLoginFailed}
                        />
                    }
                  />

                  <Route exact path="/divisions"
                    render={(props) =>
                      this.state.isSignedIn ? <DivisionHome {...props} /> : <Unauthorized />
                    }
                  />

                  <Route exact path="/church_divisions"
                    render={(props) =>
                      this.state.isSignedIn ? <ChurchDivisions {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/church_divisions/new"
                    render={(props) =>
                      this.state.isSignedIn ? <NewChurchDivision {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/church_divisions/:_id/edit"
                    render={(props) =>
                      this.state.isSignedIn ? <EditChurchDivision {...props} /> : <Unauthorized />
                    }
                  />

                  <Route exact path="/church_districts"
                    render={(props) =>
                      this.state.isSignedIn ? <ChurchDistricts {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/church_districts/new"
                    render={(props) =>
                      this.state.isSignedIn ? <NewChurchDistrict {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/church_districts/:_id/edit"
                    render={(props) =>
                      this.state.isSignedIn ? <EditChurchDistrict {...props} /> : <Unauthorized />
                    }
                  />

                  <Route exact path="/countries"
                    render={(props) =>
                      this.state.isSignedIn ? <Countries {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/countries/new"
                    render={(props) =>
                      this.state.isSignedIn ? <NewCountry {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/countries/:_id/edit"
                    render={(props) =>
                      this.state.isSignedIn ? <EditCountry {...props} /> : <Unauthorized />
                    }
                  />

                  <Route exact path="/division_attendance"
                    render={(props) =>
                      <WrappedDivisionAttendanceReport
                        {...props}
                        updateAttendanceReport={this.updateAttendanceReport}
                        setReceiptNumber={this.setReceiptNumber}
                        attendanceReport={this.state.attendanceReport}
                        receiptNumber={this.state.receiptNumber}
                        clearState={this.clearState}
                      />
                    }
                  />
                  <Route exact path="/division_attendance_receipt"
                    render={(props) =>
                      <DivisionAttendanceReceipt
                        receiptNumber={this.state.receiptNumber}
                        attendanceReport={this.state.attendanceReport}
                        clearState={this.clearState}
                      />
                    }
                  />

                  <Route exact path="/locale_churches"
                    render={(props) =>
                      this.state.isSignedIn ? <LocaleChurches {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/locale_churches/new"
                    render={(props) =>
                      this.state.isSignedIn ? <NewLocaleChurch {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/locale_churches/:localeId/edit"
                    render={(props) =>
                      this.state.isSignedIn ? <EditLocaleChurch {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/locale_churches/:localeId/population"
                    render={(props) =>
                      this.state.isSignedIn ? <LocaleChurchPopulation {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/locale_churches/:localeId/members/new"
                    render={(props) =>
                      this.state.isSignedIn ? <RegisterMember {...props} /> : <Unauthorized />
                    }
                  />

                  <Route exact path="/members"
                    render={(props) =>
                      this.state.isSignedIn ?
                      <MembersList
                        {...props}
                        isSignedIn={this.state.isSignedIn}
                        userMemberId={this.state.userMemberId}
                        membersFilter={this.state.membersFilter}
                        setMembersFilter={this.setMembersFilter}
                      />
                      :
                      <Unauthorized />
                    }
                  />
                  <Route exact path="/members/new"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <NewMember
                          {...props}
                          services={this.state.services}
                        />
                        :
                        <Unauthorized />
                    }
                  />
                  <Route exact path="/members/:memberId/edit"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <MemberForm
                          {...props}
                          services={this.state.services}
                          isSignedIn={this.state.isSignedIn}
                          userMemberId={this.state.userMemberId}
                          membersFilter={this.state.membersFilter}
                          setMembersFilter={this.setMembersFilter}
                        />
                      :
                      <Unauthorized />
                    }
                  />
                  <Route exact path="/members/:_id/history"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <MemberHistory
                          {...props}
                          userMemberId={this.state.userMemberId}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/gatherings_home"
                    render={(props) =>
                      this.state.isSignedIn ? <GatheringsHome {...props} /> : <Unauthorized />
                    }
                  />

                  <Route exact path="/gatherings"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <Gatherings
                          {...props}
                        />
                        :
                        <Unauthorized />
                    }
                  />
                  <Route exact path="/gatherings/new"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <CreateGathering
                          {...props}
                        />
                        :
                        <Unauthorized />
                    }
                  />
                  <Route exact path="/gatherings/:_id/edit"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <EditGathering
                          {...props}
                        />
                        :
                        <Unauthorized />
                    }
                  />
                  <Route exact path="/gatherings/:gatheringId/attendees"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <GatheringAttendees
                          {...props}
                        /> : <Unauthorized />
                    }
                  />

                  <Route exact path="/gathering_types"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <GatheringTypes
                          {...props}
                        />
                        :
                        <Unauthorized />
                    }
                  />
                  <Route exact path="/gathering_types/new"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <NewGatheringType
                          {...props}
                        />
                        :
                        <Unauthorized />
                    }
                  />
                  <Route exact path="/gathering_types/:_id/edit"
                    render={(props) =>
                      this.state.isSignedIn ?
                        <EditGatheringType
                          {...props}
                        />
                        :
                        <Unauthorized />
                    }
                  />

                  <Route exact path="/uploads"
                    render={(props) =>
                      this.state.isSignedIn ? <UploadHome {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/upload/members"
                    render={(props) =>
                      this.state.isSignedIn ? <UploadMembers {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/uploads/countries"
                    render={(props) =>
                      this.state.isSignedIn ? <UploadCountries {...props} /> : <Unauthorized />
                    }
                  />

                  <Route exact path="/uploads/locale_churches"
                    render={(props) =>
                      this.state.isSignedIn ? <UploadLocaleChurches {...props} /> : <Unauthorized />
                    }
                  />

                  <Route exact path="/reports"
                    render={(props) =>
                      this.state.isSignedIn ? <ReportsHome {...props} /> : <Unauthorized />
                    }
                  />

                  <Route exact path="/reports/by_gathering"
                    render={(props) =>
                      this.state.isSignedIn ? <ReportsHomeByGathering {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/reports/locale_attendance_by_gathering"
                    render={(props) =>
                      this.state.isSignedIn ? <AttendanceReportByGathering {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/reports/locale_attendance_by_gathering_elders"
                    render={(props) =>
                      this.state.isSignedIn ? <AttendanceReportByGatheringElders {...props} /> : <Unauthorized />
                    }
                  />

                  <Route exact path="/reports/division_attendance"
                    render={(props) =>
                      this.state.isSignedIn ? <DivisionAttendanceReports {...props} /> : <Unauthorized />
                    }
                  />

                  <Route exact path="/reports/division_attendance/:_id/history"
                    render={(props) =>
                      this.state.isSignedIn ? <DivisionAttendanceReportHistory {...props} /> : <Unauthorized />
                    }
                  />

                  <Route exact path="/registration"
                    render={(props) =>
                      <MemberRegistration
                        {...props}
                        setCandidateReferenceNumber={this.setCandidateReferenceNumber}
                        clearState={this.clearState}
                      />
                    }
                  />

                  <Route exact path="/registration_receipt"
                    render={(props) =>
                      <RegistrationReceipt
                      referenceNumber={this.state.referenceNumber}
                        clearState={this.clearState}
                      />
                    }
                  />

                  <Route exact path="/ministry_home"
                    render={(props) =>
                      this.state.isSignedIn ? <MinistryHome {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/ministries"
                    render={(props) =>
                      this.state.isSignedIn ? <Ministries {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/ministries/new"
                    render={(props) =>
                      this.state.isSignedIn ? <CreateMinistry {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/ministries/:_id/edit"
                    render={(props) =>
                      this.state.isSignedIn ? <EditMinistry {...props} /> : <Unauthorized />
                    }
                  />

                  <Route exact path="/ministry_attendance/workers"
                    render={(props) =>
                      <MinistryAttendance
                        {...props}
                        ministry="workers"
                        scope={"division"}
                        activeActivity={this.state.activeActivity}
                        checkedMembers={this.state.selectedMembers}
                        mode={this.state.mode}
                        receiptNumber={this.state.receiptNumber}
                        setMembers={this.setMembers}
                        setMode={this.setMode}
                        setActiveActivity={this.setActiveActivity}
                        setReceiptNumber={this.setReceiptNumber}
                        clearState={this.clearState}
                        activeGathering={this.state.activeGathering}
                        setActiveGathering={this.setActiveGathering}
                        setGatheringReceiptNumber={this.setGatheringReceiptNumber}
                      />
                    }
                  />

                  <Route exact path="/ministry_attendance_receipt/workers"
                    render={(props) =>
                      <MinistryAttendanceReceipt
                        ministry="workers"
                        scope={"division"}
                        receiptNumber={this.state.receiptNumber}
                        gatheringReceiptNumber={this.state.gatheringReceiptNumber}
                        checkedMembers={this.state.selectedMembers}
                        clearState={this.clearState}
                        activeActivity={this.state.activeActivity}
                        activeGathering={this.state.activeGathering}
                      />
                    }
                  />

                  <Route exact path="/system"
                    render={(props) =>
                      this.state.isSignedIn ? <SystemHome {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/system/settings"
                    render={(props) =>
                      this.state.isSignedIn ? <Settings {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/system/settings/new"
                    render={(props) =>
                      this.state.isSignedIn ? <NewSetting {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/system/settings/:_id/edit"
                    render={(props) =>
                      this.state.isSignedIn ? <EditSetting {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/system/roles"
                    render={(props) =>
                      this.state.isSignedIn ? <Roles {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/system/roles/new"
                    render={(props) =>
                      this.state.isSignedIn ? <NewRole {...props} /> : <Unauthorized />
                    }
                  />
                  <Route exact path="/system/roles/:_id/edit"
                    render={(props) =>
                      this.state.isSignedIn ? <EditRole {...props} /> : <Unauthorized />
                    }
                  />

                </div>
              </Col>
            </Row>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

export default withTranslation()(withRouter(DivisionWrapper));
