import React, { Component } from 'react';
import {
  Button, Col, Form, Row, Select, message, Input, InputNumber, DatePicker,
  Radio, Table,
} from 'antd';
import { withTranslation } from 'react-i18next';
import ReactGA from 'react-ga';

import moment from 'moment';
import _ from 'lodash';
import * as qs from 'query-string';

import amsAPI from '../../apis/amsAPI';
import * as constants from '../../helpers/constants';

import 'antd/dist/antd.css';
import './Home.css';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
};

let timeout;
let currentValue;

function fetch(value, url, callback) {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = value;

  async function fake() {
    amsAPI.getUrl(url)
      .then(async response => {
        const body = await response.json();
        if (response.status !== 200) throw Error(body.message);
        return body;
      })
      .then(d => {
        if (currentValue === value) {
          callback(d.data);
        }
      });
  }

  timeout = setTimeout(fake, 300);
}

class ClientDivisionReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      receiptNumber: '',
      mode: 'new',
      members: [],
      localeChurches: [],
      churchDivisions: [],
      churchDistricts: [],
      countries: [],
      history: [],
      gatheringTypes: [],
      submitting: false,
      populationRecord: {},
    };

    this.columns = [
      {
        title: 'Changed By',
        dataIndex: 'changedBy',
        key: 'rowKey._id',
        width: 150,
        render: (text, record) => (
          <span>
            {record.changedBy ? record.changedBy.name : ""}
          </span>
        ),
      },
      {
        title: 'Changed On',
        dataIndex: 'changedAt',
        key: 'changedAt',
        width: 120,
        render: (text, record) => (
        <span>
          {record.changedAt ? moment(record.changedAt).format("YYYY-MM-DD") : null}
        </span>
        )
      },
      {
        title: 'Content',
        dataIndex: 'comment',
        key: 'comment',
        render: comment => <span>{comment}</span>,
      },
    ];
  }

  componentDidMount() {
    this.getRequiredInfoFromAPI();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.getRequiredInfoFromAPI();
    }
  }

  getRequiredInfoFromAPI = async () => {
    this.callApi(`/api/gathering_types`)
      .then(res => this.setState({ gatheringTypes: res.data, loading: false }))
      .catch(err => console.log(err));
    this.callApi('/ams/church_divisions')
      .then(res => {
        this.setState({ churchDivisions: res.data })
      });
    this.callApi('/ams/church_districts')
      .then(res => {
        this.setState({ churchDistricts: res.data })
      });
    this.callApi('/ams/countries')
      .then(res => {
        this.setState({ countries: res.data })
      });
  }

  getReport = () => {
    const { receiptNumber } = this.state;
    const query = { receiptNumber };
    const url = `/api/attendance_reports?${qs.stringify(query)}`
    this.callApi(url)
      .then(res => {
        const record = res.data && res.data[0];
        this.callApi(`/ams/members/${record.reportedBy._id}`)
          .then(res => {
            this.setState({ members: [res.member] });
            this.props.updateAttendanceReport({
              ...record,
              reportedBy: record.reportedBy._id,
            });
            this.props.form.setFieldsValue({ reportedBy: record.reportedBy._id });
          });
        this.callApi(`/api/attendance_reports/${record._id}/history`)
          .then(res => {
            const history = res.data;
            this.setState({ history });
          })
        this.callApi(`/api/locale_churches/${record.localeChurch}`)
          .then(res => {
            const localeChurch = res.data;
            this.setState({ localeChurches: [ localeChurch ] });
          })
        this.callApi(`/ams/locale_churches/${record.localeChurch}/population`)
          .then(res => {
            this.setState({ populationRecord: res.data })
          });
      })
    }

  callApi = async (url) => {
    const response = await amsAPI.getUrl(url)
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  handleSubmitAttendance = async (e) => {
    ReactGA.event({
      category: 'Button Click',
      action: 'submit attendance report'
    });

    e.preventDefault();
    this.props.form.validateFields(err => {
      if (!err) {
        this.setState({ submitting: true });
        const { t, attendanceReport } = this.props;

        amsAPI.fetchUrl(`/ams/attendance_reports`, {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({...attendanceReport}),
        })
        .then(async res => {
          if (res.status === 200) {
            const response = await res.json();
            console.log('response', response)
            if (!_.isEmpty(response)) {
              message.success('Attendance successfully submitted.');
              const { receiptNumber } = response.data;
              this.props.setReceiptNumber(receiptNumber);
              this.setState({ submitting: false });
              this.props.history.push(`/division_attendance_receipt`);
            }
          } else {
            const error = new Error(res.error);
            throw error;
          }
        })
        .catch(err => {
          console.error(err);
          this.setState({ submitting: false });
          message.error(t('Error submitting attendance.'));
        });
      } else {
        const firstErrField = Object.keys(err)[0];
        this[firstErrField].focus();
      }
    });
  };

  handleSearch = value => {
    if (value) {
      const query = {
        q: value,
      };
      fetch(value, `/ams/members?${qs.stringify(query)}`, data => {
        this.setState({ members: data })
      });
    } else {
      this.setState({ members: [] });
    }
  };

  handleChange = value => {
    this.props.updateAttendanceReport({ reportedBy: value });
    this.props.form.setFieldsValue({ reportedBy: value });
  };

  handleChurchDivisionSelect = async (value) => {
    this.props.updateAttendanceReport({ churchDivision: value });
    const query = {
      churchDivision: value,
    };
    this.callApi(`/ams/church_districts?${qs.stringify(query)}`)
      .then(res => {
        this.setState({ churchDistricts: res.data })
      })
  };

  handleLocaleSelect = async (value) => {
    this.props.updateAttendanceReport({ localeChurch: value });
    this.callApi(`/api/locale_churches/${value}`)
      .then(res => {
        const { churchDivision, churchDistrict, country } = res.data;
        this.props.updateAttendanceReport({ churchDivision, churchDistrict, country });
      })
    this.callApi(`/ams/locale_churches/${value}/population`)
      .then(res => {
        this.setState({ populationRecord: res.data })
      });
  };

  handleLocaleSearch = value => {
    if (value) {
      const query = {
        name: value,
      };
      fetch(value, `/ams/locale_churches/simple?${qs.stringify(query)}`, data => {
        this.setState({ localeChurches: data })
      });
    } else {
      this.setState({ localeChurches: [] });
    }
  };

  render() {
    const {
      members, localeChurches, churchDivisions, churchDistricts, countries,
      receiptNumber, mode, history, populationRecord, gatheringTypes,
    } = this.state;
    const { t, attendanceReport } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { localeChurch } = attendanceReport;
    const { registered, inactive, inconsistent } = populationRecord;
    const gatheringEntries = [];
    gatheringTypes.forEach(item => {
      gatheringEntries.push([item.code, item.name]);
    })
    const replayOrLive = Object.entries(constants.replayOrLive);

    let modResult = [];
    let i = 0;
    history.forEach(item => {
      i++;
      modResult.push({ ...item, key: item._id, rowKey: { _id: item._id, rowNum: i } });
    });

    const isEditMode = (mode === "update");
    const disableSubmit = isEditMode && !receiptNumber;
    return (
      <div className="wrap">
        <div className="extraContent">
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={6}>
              <h3>{t("AOD MED Online Attendance Report")}</h3>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={16}>
              <Form layout="horizontal" {...formItemLayout} >
                <Form.Item label="Category">
                  <Radio.Group
                    onChange={e => {
                      const { value } = e.target;
                      this.setState({ mode: value, receiptNumber: null, history: [], populationRecord: {} });
                      this.props.clearState();
                    }}
                    defaultValue={this.state.mode}
                  >
                    <Radio value={"new"}>New</Radio>
                    <Radio value={"update"}>Update</Radio>
                  </Radio.Group>
                </Form.Item>
                {this.state.mode === "update" &&
                  <div>
                    <Form.Item label="Receipt Number">
                      <Input
                        onChange={e => this.setState({ receiptNumber: e.target.value })}
                      />
                      <Button
                        onClick={this.getReport}
                        disabled={!this.state.receiptNumber}
                      >Retrieve</Button>
                    </Form.Item>
                  </div>
                }
              </Form>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={18}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <h3>Report Information</h3>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={18}>
              <Form layout="horizontal" {...formItemLayout} >
                <Form.Item label={t("Reported By")}>
                {getFieldDecorator('reportedBy', {
                  rules: [
                    {
                      required: true,
                      message: 'Please input your name',
                    },
                  ],
                })(
                  <Select
                    showSearch
                    placeholder={t("Input member name or id")}
                    dropdownMatchSelectWidth={false}
                    mode={this.state.mode}
                    optionFilterProp="value"
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    onSearch={this.handleSearch}
                    onChange={this.handleChange}
                    notFoundContent={null}
                    ref={input => {
                      this.reportedBy = input;
                    }}
                    value={attendanceReport.reportedBy}
                    disabled={isEditMode}
                  >
                    {members.map(item => {
                      return (
                        <Option key={item._id} value={item._id}>
                          {`${item.churchId} ${item.name}`}
                        </Option>
                      )
                    })}
                  </Select>
                )}
                </Form.Item>
                <Form.Item label="Email">
                  <Input
                    onChange={e => this.props.updateAttendanceReport({ email: e.target.value })}
                    value={attendanceReport.email}
                    disabled={isEditMode}
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={18}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <h3>Locale Information</h3>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={18}>
              <Form {...formItemLayout}>
                <Form.Item label="Locale:">
                  <Select
                    showSearch
                    placeholder="Select a locale"
                    dropdownMatchSelectWidth={false}
                    defaultActiveFirstOption={false}
                    filterOption={false}
                    allowClear={true}
                    showArrow={false}
                    onChange={this.handleLocaleSelect}
                    onSearch={this.handleLocaleSearch}
                    notFoundContent={null}
                    value={localeChurch}
                    disabled={isEditMode}
                  >
                    {localeChurches && localeChurches.map(item => {
                      return <Option key={item._id}>{item.name}</Option>
                    })}
                  </Select>
                </Form.Item>
                <Form.Item label="Division:">
                  <Select
                    placeholder="Select a division"
                    dropdownMatchSelectWidth={false}
                    onChange={this.handleChurchDivisionSelect}
                    value={attendanceReport.churchDivision ? attendanceReport.churchDivision : ""}
                    disabled={isEditMode}
                  >
                    {churchDivisions.map(item => {
                      return <Option key={item._id} value={item._id}>{item.name}</Option>
                    })}
                  </Select>
                </Form.Item>
                <Form.Item label="District:">
                  <Select
                    placeholder="Select a district"
                    dropdownMatchSelectWidth={false}
                    onChange={value => this.props.updateAttendanceReport({ churchDistrict: value })}
                    disabled={churchDistricts.length === 0 || isEditMode}
                    value={attendanceReport.churchDistrict ? attendanceReport.churchDistrict : ""}
                  >
                    {churchDistricts.map(item => {
                      return <Option key={item._id} value={item._id}>{item.name}</Option>
                    })}
                  </Select>
                </Form.Item>
                <Form.Item label="Country:">
                  <Select
                    placeholder="Select a country"
                    dropdownMatchSelectWidth={false}
                    onChange={value => this.props.updateAttendanceReport({ country: value })}
                    disabled={countries.length === 0 || isEditMode}
                    value={attendanceReport.country ? attendanceReport.country : ""}
                  >
                    {countries.map(item => {
                      return <Option key={item._id} value={item._id}>{item.name}</Option>
                    })}
                  </Select>
                </Form.Item>
                <Form.Item label="Registered">
                  <InputNumber
                    value={registered}
                    disabled={isEditMode}
                  />
                </Form.Item>
                <Form.Item label="Inactive">
                  <InputNumber
                    value={inactive}
                    disabled={isEditMode}
                  />
                </Form.Item>
                <Form.Item label="Inconsistent">
                  <InputNumber
                    value={inconsistent}
                    disabled={isEditMode}
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={18}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <h3>Service Information</h3>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={18}>
              <Form layout="horizontal" {...formItemLayout} >
                <Form.Item label="Date of Gathering">
                  <DatePicker
                    onChange={(value) => this.props.updateAttendanceReport({ gatheringDate: value })}
                    value={attendanceReport.gatheringDate ? moment(attendanceReport.gatheringDate) : null}
                    disabled={isEditMode}
                  />
                </Form.Item>
                <Form.Item label="Service Type">
                  <Select
                    showSearch
                    placeholder="Select a gathering"
                    dropdownMatchSelectWidth={false}
                    style={{ width: 240 }}
                    onChange={value => this.props.updateAttendanceReport({ gatheringName: value })}
                    value={attendanceReport.gatheringName}
                    disabled={isEditMode}
                  >
                    {gatheringEntries.map(([id, name]) =>
                      <Option key={id} value={id}>{name}</Option>
                    )}
                  </Select>
                </Form.Item>
                <Form.Item label="Live or Replay">
                  <Select
                    showSearch
                    placeholder="Select a gathering type"
                    dropdownMatchSelectWidth={false}
                    style={{ width: 240 }}
                    onChange={value => this.props.updateAttendanceReport({ gatheringType: value })}
                    value={attendanceReport.gatheringType}
                    disabled={isEditMode}
                  >
                    {replayOrLive.map(([id, name]) =>
                      <Option key={id} value={id}>{name}</Option>
                    )}
                  </Select>
                </Form.Item>
              </Form>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={18}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <h3>Attendance Statistics</h3>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={18}>
              <Form layout="horizontal" {...formItemLayout} >
                <Form.Item label="Newly Baptized">
                  <InputNumber
                    onChange={value => this.props.updateAttendanceReport({ newlyBaptized: value })}
                    value={attendanceReport.newlyBaptized}
                  />
                </Form.Item>
                <Form.Item label="Locale Attendance">
                  <InputNumber
                    onChange={value => this.props.updateAttendanceReport({ localeAttendance: value })}
                    value={attendanceReport.localeAttendance}
                  />
                </Form.Item>
                <Form.Item label="Hookup Attendance">
                  <InputNumber
                    onChange={value => this.props.updateAttendanceReport({ hookupAttendance: value })}
                    value={attendanceReport.hookupAttendance}
                  />
                </Form.Item>
                <Form.Item label="Visiting Brethren">
                  <InputNumber
                    onChange={value => this.props.updateAttendanceReport({ visitingBrethren: value })}
                    value={attendanceReport.visitingBrethren}
                  />
                </Form.Item>
                <Form.Item label="Overseas">
                  <InputNumber
                    onChange={value => this.props.updateAttendanceReport({ overseasBrethren: value })}
                    value={attendanceReport.overseasBrethren}
                  />
                </Form.Item>
                <Form.Item label="Late">
                  <InputNumber
                    onChange={value => this.props.updateAttendanceReport({ lateAttendees: value })}
                    value={attendanceReport.lateAttendees}
                  />
                </Form.Item>
                <Form.Item label="Guests">
                  <InputNumber
                    onChange={value => this.props.updateAttendanceReport({ guests: value })}
                    value={attendanceReport.guests}
                  />
                </Form.Item>
                <Form.Item label="Number of Gadgets">
                  <InputNumber
                    onChange={value => this.props.updateAttendanceReport({ numberOfDevices: value })}
                    value={attendanceReport.numberOfDevices}
                  />
                </Form.Item>
                <Form.Item label="Reason for absence">
                  <Input
                    onChange={e => this.props.updateAttendanceReport({ reasonForAbsence: e.target.value })}
                    value={attendanceReport.reasonForAbsence}
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={6}>
              <Button block type="primary"
                loading={this.state.submitting}
                onClick={this.handleSubmitAttendance}
                disabled={disableSubmit}
              >
                {t("Submit")}
              </Button>
            </Col>
          </Row>
          {history.length > 0 &&
            <Row type="flex" justify="center" style={{ marginTop: "10px" }}>
              <Col xs={24} sm={24} md={24} lg={12}>
                <Table
                  columns={this.columns}
                  dataSource={modResult}
                  pagination={{hideOnSinglePage: true}}
                />
              </Col>
            </Row>
          }
        </div>
      </div>
    );
  }
}

export default withTranslation()(ClientDivisionReport);
