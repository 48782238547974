import React, { Component } from 'react';

import { PDFViewer } from '@react-pdf/renderer';
import MyDocument from './MyDocument';

class PdfLocaleReport extends Component {

  render() {
    return (
      <PDFViewer>
        <MyDocument />
      </PDFViewer>
    );
  }
}

export default PdfLocaleReport;
